import { Cog, Upload, X } from "lucide-react";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { testStrategy } from "../utils/web-services";
import { Overview } from "../components/tester/overview";
import { ListOfTrades } from "../components/tester/list-of-trades";
import { PerformanceSummary } from "../components/tester/performance-summary";

export function Tester() {
    const [loading, setLoading] = useState(false);
    const [testerPreference, setTesterPreference] = useState({
        START_DATE: null,
        END_DATE: null,
        INITIAL_CAPITAL: 100000,
        COMMISSION: 0.02,
        COMMISSION_TYPE: "PERCENTAGE",
        CONTRACTS: 1000,
        MAX_HOLDING_DAY: 20,
    });

    const [trades, setTrades] = useState(null);
    const [performance, setPerformance] = useState(null);

    const settingDialog = useRef(null);
    const inputRef = useRef(null);
    
    const [tab, setTab] = useState("overview");

    const tabs = {
        "overview": <Overview performance={performance}/>,
        "list": <ListOfTrades trades={trades}/>,
        "performance": <PerformanceSummary performance={performance}/>,
    }

    const content = tabs[tab];

    const handleOpenModal = () => settingDialog.current.showModal();

    const handleOnChange = (event) => {
        setTesterPreference((prev) => {
            const preferences = {...prev};
            const key = event.target.name;
            const value = event.target.value;
            preferences[key] = value;
            return preferences;
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const file = inputRef.current.files[0];
            if(!!file) {
                await toast.promise(testStrategy(file, testerPreference), {
                    loading: `Analysing`,
                    success: (results) => {
                        setPerformance(results.performance);
                        setTrades(results.trades);
                        return "Done"
                    },
                    error: (error) => {
                        setPerformance(null);
                        setTrades(null);
                        return error.message ?? "Something went wrong"
                    },
                });
            } else {
                toast.error("Pls select a file first");
            }
        } catch (error) {
            console.error(error);   
            setPerformance(null);
            setTrades(null);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <div className="w-full min-h-screen">
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="flex flex-col space-y-4 overflow-auto p-4">
                        <h2 className="text-3xl font-bold mb-4">Strategy Tester</h2>
                        <div className="flex flex-col align-center w-full gap-4">
                            <div className="flex flex-col md:flex-row w-full gap-2">
                                <div className="border w-full flex flex-row">
                                    <input  ref={inputRef} type="file" className="file-input flex-1 rounded-none cursor-pointer focus:outline-none hover:bg-base-200" multiple={true} accept='.csv' />
                                    <button className="btn btn-primary btn-square rounded-none" multiple={false} onClick={handleSubmit} accept=".csv">
                                            {
                                                loading
                                                    ? <span className='loading  loading-xs'></span>
                                                    : <Upload />
                                            }
                                        </button>
                                </div>
                                <div className="divider md:divider-horizontal !m-0" />
                                <button className="btn w-full md:btn-square" onClick={handleOpenModal}>
                                    <Cog />
                                </button>
                            </div>
                            <div className="divider m-0" />
                            <div role="tablist" className="tabs tabs-boxed">
                                {
                                    Object.keys(tabs).map((key, index) => (
                                        <div key={index} role="tab" className={`tab ${key === tab ? "tab-active" : ""}`} onClick={() => setTab(key)}>{key.toUpperCase()}</div>
                                    ))
                                }
                            </div>
                            <div className="border-2 h-[90vh] flex-center overflow-auto scrollbar-hide">
                                {
                                    loading
                                    ? <div className="loading loading-lg loading-bars "/>
                                    : !trades || !performance 
                                        ? <div className="w-full h-full flex-center">No Result</div>
                                        : (content)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <dialog ref={settingDialog} className="modal">
                <div className="modal-box w-11/12 max-w-3xl">
                    <h3 className="font-bold text-lg">Tester Preferences</h3>
                    <div className="py-4">
                        {
                            !!testerPreference 
                            ? (
                                <div className="flex flex-col gap-4">
                                    {
                                        Object.keys(testerPreference).map((preference, index) => {
                                            if (preference === "COMMISSION_TYPE") {
                                                return (
                                                    <div key={index}>
                                                        <label className="input input-bordered flex justify-between items-center gap-2 pr-0">
                                                            {preference}
                                                            <select defaultValue={"PERCENTAGE"} name={preference} className="select select-ghost border-none h-full w-auto text-end pr-[2rem] bg-transparent focus:outline-none">
                                                                <option value={"PERCENTAGE"}>PERCENTAGE(%)</option>
                                                                <option value={"PRICE"}>PRICE</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                )
                                            }

                                            const placeholder = ["START_DATE", "END_DATE"].includes(preference) ? "YYYY-MM-DD" : "? "
                                            const type = ["INITIAL_CAPITAL", "COMMISSION", "CONTRACTS", "MAX_HOLDING_DAY"].includes(preference) ? "number" : "text";
                                            return (
                                                <div key={index}>
                                                    <label className="input input-bordered flex items-center gap-2">
                                                        {preference}
                                                        <input className="grow text-end" placeholder={placeholder} type={type} name={preference} value={testerPreference[preference]} onChange={handleOnChange} />
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                            : <div className="flex-center mt-8"><div className="loading" /></div> 
                        }
                    </div>
                    <form method="dialog" className="flex-center absolute top-3 right-3">
                        <button><X /></button>
                    </form>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
    )
}