import { useMemo, useState } from "react";
import { formatNumberWithCommas, formatPrice, isoStringToYYYYMMDD } from "../../utils/common"
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from 'react-window';
import { ChevronDown, ChevronUp } from "lucide-react";



export function ScreenerTable({ data }) {
    const [sortBy, setSortBy] = useState("");
    const [orderBy, setOrderBy] = useState("asc");

    const screeners = useMemo(() => {
        let filteredData = data ?? [];

        if (sortBy) {
            filteredData.sort((a, b) => {
                if (["TICKER", "EXCHANGE", "DATE", "FORMULA"].includes(sortBy)) {
                    return orderBy === "asc" ? (a[sortBy] > b[sortBy] ? 1 : -1) : (a[sortBy] < b[sortBy] ? 1 : -1);
                } else {
                    return orderBy === "asc" ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
                }
            });
        }
        
        return filteredData;
    }, [data, sortBy, orderBy]);

    const headers = [
        "FORMULA",
        "DATE",
        "EXCHANGE",
        "TICKER",
        "BUY_IN",
        "CUT_LOSS",
        "TAKE_PROFIT",
        "TICK",
        "OPEN",
        "HIGH",
        "LOW",
        "CLOSE",
        "VOLUME",
        "SHARE_ISSUE",
        "EMA3",
        "EMA6",
        "EMA9",
        "EMA12",
        "EMA30",
        "EMA50"
    ];

    const priceHeaders = [
        "BUY_IN",
        "CUT_LOSS",
        "TAKE_PROFIT",
        "OPEN",
        "HIGH",
        "LOW",
        "CLOSE",
        "SHARE_ISSUE",
        "EMA3",
        "EMA6",
        "EMA9",
        "EMA12",
        "EMA30",
        "EMA50"
    ]

    const columnWidths = [
        60,
        150,
        150,
        150,
        100,
        130,
        130,
        180,
        80,
        130,
        130,
        130,
        130,
        200,
        300,
        130,
        130,
        130,
        130,
        130,
        130,
    ];

    return (
        <>
            <div className="block w-full h-[90vh] border-2">
                {
                    screeners && screeners.length > 0
                        ? <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <>
                                        <Grid
                                            columnCount={headers.length + 1}
                                            rowCount={screeners.length + 1 ?? 1}
                                            height={height - 4}
                                            width={width - 4}
                                            columnWidth={(i) => columnWidths[i]}
                                            rowHeight={() => 50}
                                        >
                                            {({ columnIndex, rowIndex, style }) => {
                                                const header = headers[columnIndex - 1];
                                                const screener = screeners[rowIndex - 1];
                                                let cell;
                                                let extraClassName = "";


                                                if (columnIndex === 0 && rowIndex === 0) {
                                                    return (<p style={style} className="flex-center border-b-2">NO</p>)
                                                }

                                                if (columnIndex === 0) {
                                                    return (<p style={style} className="flex-center border-b-2">{rowIndex}</p>)
                                                }
                                                if (rowIndex === 0) {
                                                    return (
                                                        <p style={style} className="flex-center border-b-2 cursor-pointer !flex-row gap-1"
                                                            onClick={() => {
                                                                if (sortBy === header) {
                                                                    setOrderBy(prev => prev === "asc" ? "desc" : "asc");
                                                                } else {
                                                                    setSortBy(header);
                                                                }
                                                            }}
                                                        >
                                                            {header.replaceAll("_", " ")}
                                                            {
                                                                sortBy === header
                                                                ? orderBy === "asc"
                                                                    ? <ChevronDown />
                                                                    : <ChevronUp />
                                                                : null
                                                            }
                                                        </p>
                                                    )
                                                }



                                                if (header === "DATE") {
                                                    extraClassName += "items-center";
                                                    cell = isoStringToYYYYMMDD(screener[header]);
                                                } else if (header === "VOLUME") {
                                                    extraClassName += "items-end"
                                                    cell = formatNumberWithCommas(screener[header]);
                                                } else if (priceHeaders.includes(header)) {
                                                    extraClassName += "items-end"
                                                    cell = formatPrice(screener[header]);
                                                } else if (!screener[header]) {
                                                    extraClassName += "items-end"
                                                    cell = "?"
                                                } else {
                                                    extraClassName += "items-center";
                                                    cell = screener[header];
                                                }

                                                if (header === "TICKER") {
                                                    return (
                                                        <div style={style} className={`border-b-2 flex flex-col justify-center ${extraClassName}`}>
                                                            <a className="btn btn-link p-0 select-text" href={`https://www.tradingview.com/chart/?symbol=${screener["EXCHANGE"]}:${screener["TICKER"]}`} target="_blank" rel="noreferrer">
                                                                {cell}
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <div style={style} className={`border-b-2 flex flex-col justify-center px-4 ${extraClassName}`}>
                                                        {cell}
                                                    </div>
                                                )
                                            }
                                            }
                                        </Grid>
                                    </>
                                )
                            }}
                        </AutoSizer>
                        : (<div className="w-full h-full flex-center">No data found.</div>)
                }
            </div>
        </>
    )
}