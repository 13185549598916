import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Upload } from 'lucide-react';
import { uploadMyxData } from "../utils/web-services";

export function DataUpload() {
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = async (event) => {

    }

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const file = inputRef.current.files[0];
            if(!!file) {
                await toast.promise(uploadMyxData(file), {
                    loading: `Updating MYX Data`,
                    success: (affectedRows) => `Update MYX Data Successful, ${affectedRows} records has been changed`,
                    error: (error) => error.message ?? "Something went wrong",
                });
            }            
        } catch (error) {
            console.error(error);   
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="flex flex-col md:flex-row w-full items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex flex-row flex-1 border w-full gap-4">
                    <input ref={inputRef} type="file" className="file-input w-full" multiple={false} accept='.txt,text/plain'/>
                    <button className="btn btn-square" onClick={handleSubmit} onChange={handleFileChange}>
                        {
                            isLoading
                                ? <span className='loading  loading-xs'></span>
                                : <Upload />
                        }
                    </button>
                </div>
            </div>
        </>
    )
}