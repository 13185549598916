import Datepicker from "react-tailwindcss-datepicker";
import { isoStringToYYYYMMDD } from "../../utils/common";
import { useEffect, useState } from "react";
import { fetchScreeners } from "../../utils/web-services";

export function ByDate({ onChange }) {
    const [loading, setLoading] = useState(false);

    const today = isoStringToYYYYMMDD(new Date().toISOString());

    const [date, setDate] = useState({
        startDate: today,
        endDate: today,
    });

    const handleDateChange = (value) => setDate(value);

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (!!date.endDate) {
                const result = await fetchScreeners("date", date.endDate);
                onChange(result);
            }
            setLoading(false)   
        })()
    }, [date]);

    return (
        <div className="btn z-20 w-full h-full relative" id="calendar">
            {
                loading
                ? <div className="bg-base-200 h-full w-full flex-center"><div className="loading loading-bars"/></div>
                : <Datepicker
                    primaryColor="blue"
                    inputClassName={"min-h-12 bg-transparent w-full text-start text-inherit focus:outline-none"}
                    useRange={false}
                    asSingle={true}
                    value={date}
                    onChange={handleDateChange}
                    displayFormat={"MMM DD, YYYY"}
                />
            }
        </div>
    )
}