import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Upload } from './pages/upload';
import { Layout } from './pages/layout';
import { Tools } from './pages/tools';
import { NotFound } from './pages/not-found';
import { Danger } from './pages/danger';
import { Toaster } from 'react-hot-toast';
import { Data } from './pages/data';
import { Market } from './pages/market';
import { Tester } from './pages/tester';
import PrivateRoute from './routers/private-router';
import { Login } from './pages/login';

  function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />}/>
          <Route element={<PrivateRoute />}>
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='market' element={<Market/>} />
              <Route path='tester' element={<Tester/>} />
              <Route path='upload' element={<Upload />} />
              <Route path="tools" element={<Tools />} />
              <Route path="danger" element={<Danger />} />
              <Route path="data" element={<Data />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </Router>
      <Toaster position='bottom-right' />
    </>
  );
}

export default App;
