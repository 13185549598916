import { Download, StepForward } from 'lucide-react';
import { useRef, useState } from 'react';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';

export function EodDataToCsv() {
    const [loading, setLoading] = useState(false);
    const [zipName, setZipName] = useState("No file here");
    const [zipBlob, setZipBlob] = useState(null);
    const inputRef = useRef(null);

    const handleProcess = async () => {
        try {
            await toast.promise(
                processData(),
                {
                    loading: "Converting txt file to csv...",
                    success: "Result is ready to be saved!",
                    error: "Something went wrong.",
                }
            );
            
        } catch (error) {
            console.error(error);
        }
    }

    const processData = async () => {
        setLoading(true);
        const files = [...inputRef.current.files];
        const zip = new JSZip();
        const csvHeader = "Ticker,Date,Open,High,Low,Close,Volume\n";

        if (files.length < 1) {
            toast.error("Please choose your file first");
            setLoading(false);
            return;
        }

        for (const file of files) {
            const content = await file.text();
            const csvContent = csvHeader + content;
            zip.file(file.name.replace('.txt', '.csv'), csvContent);
        }

        const blob = await zip.generateAsync({ type: 'blob' });
        setZipBlob(blob);
        setZipName("eod_data")
        setLoading(false);
    }

    const handleDownload = () => {
        if (zipBlob) {
            saveAs(zipBlob, `${zipName || "result"}.zip`);
        } else {
            toast.error("Nothing can download")
        }
    };

    return (
        <>
            <div className=" flex flex-col lg:flex-row w-full items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4">
                <div className="flex-1 border w-full">
                    <input ref={inputRef} type="file" className="file-input" multiple={true} accept='.txt,text/plain' />
                </div>
                <div className="divider w-full  flex-1 min-h-12 m-0 ">
                    <button className="btn btn-square btn-ghost" onClick={handleProcess}>
                        {
                            loading
                                ? <span className='loading loading-bars loading-xs rotate-90 lg:rotate-0'></span>
                                : <StepForward className='rotate-90 lg:rotate-0' />
                        }
                    </button>
                </div>
                <div className="flex-1 border p-[1px] w-full min-h-12 flex flex-row justify-between items-center">
                    <div className='px-4'>
                        <input className='input focus:outline-none focus:border-none p-0 m-0' type="text" value={zipName} onChange={e => setZipName(e.target.value)} />
                    </div>
                    <button className="btn btn-square btn-primary rounded-none" disabled={loading} onClick={handleDownload}>
                        <Download />
                    </button>
                </div>
            </div>
        </>
    )
}