import React from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { toast } from "react-hot-toast";
import { isoStringToYYYYMMDD } from '../utils/common';
import { FaFileCsv } from "react-icons/fa6";

const DownloadCSVButton = ({data, filename, excludeHeaders = []}) => {

  const handleDownload = async () => {
    if(!data || data.length < 1) {
        toast.error("No data found");
        return;
    }
    
    try {
      const csvFileName = !filename ? "data.csv" : filename + ".csv";
      data.forEach(row => {
        if(row["DATE"]) {
          row["DATE"] = isoStringToYYYYMMDD(row["DATE"]);
        }

        for(const header of excludeHeaders) {
          delete row[header];
        }
      });
      const csv = Papa.unparse(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      toast(`Start downloading the ${csvFileName}`);
      saveAs(blob, csvFileName);
    } catch (err) {
      console.error('Error generating CSV file:', err);
      toast.error("Something went wrong");
    }
  };

  return (
    <button className="btn w-full h-full" onClick={handleDownload}>
        <FaFileCsv size={22} />
    </button>
  );
};

export default DownloadCSVButton;
