import { Papa } from "papaparse";

export const preventDefault = (e) => e.preventDefault();

export const formulaList = [
    'EMA_MEET',
    'EMA_MEET_PRO',
    'DROP_GAO_GAO',
    'PRICE_DIMINISH',
    'WINDICATOR',
];

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


export const convertFilenameToYYYYMMDD = (filename) => {
    const match = filename.match(/^(\d{8})\.csv$/);

    if (!match) return null;
    
    const dateString = match[1];
    
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    
    const date = new Date(`${year}-${month}-${day}`);
    if (date.toString() === "Invalid Date") return null;
    
    return `${year}-${month}-${day}`;
};

export const isoStringToTimestamp = (isoString) => {
    return Date.parse(isoString);
}
export const isoStringToYYYYMMDD = (isoString) => {
    const iso = new Date(isoString);
    const year = iso.getFullYear();
    const month = iso.getMonth() + 1;
    const date = iso.getDate();

    const formatedMonth = month.toString().padStart(2, "0");
    const formatedDate = date.toString().padStart(2, "0");
    return `${year}-${formatedMonth}-${formatedDate}`;
}
  
export  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatPrice = (price, fractionDigits = 4) =>  {
    const numericPrice = parseFloat(price);
    return numericPrice.toLocaleString('en-US', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
}
export const removeLeadingZeros = (str) => {
    return str.replace(/^0+/, '');
}

export const arrayToTVImportList = (data) => {
    return data.map(item => `${item.EXCHANGE}:${item.TICKER}`).join('\n');
};
export const arrayToFiavestImportList = (data) => {
    return data.map(item => `${item.STOCK_CODE}`).join('\n');
};
export const arrayToMooMooImportList = (data) => {
    const result = [];
    for (const stock of data) {
        if(!!stock["MOOMOO_CODE"]) {
            const mooMooCode = stock["MOOMOO_CODE"];
            if(stock["EXCHANGE"] === "MYX" && !!stock["STOCK_CODE"]) {
                const stockCode = removeLeadingZeros(stock["STOCK_CODE"]);
                result.push(`${mooMooCode}#${stockCode}`);
            } else {
                const ticker = stock["TICKER"];
                result.push(`${mooMooCode}#${ticker}`);
            }
        }
    }
    return result.join('\n');
};

export const parseCSVWithHeaders =  (csvFile, expectedHeaders = []) => {
    return new Promise((resolve, reject) => {
        Papa.parse(csvFile, {
            header: true,
            complete: function(results) {
                if (expectedHeaders.length > 0) {
                    const headers = results.meta.fields;
                    const hasAllHeaders = expectedHeaders.every(header => headers.includes(header));

                    if (!hasAllHeaders) {
                        resolve(null);
                    } else {
                        resolve(results.data);
                    }
                } else {
                    resolve(results.data);
                }
            },
            error: function(error) {
                reject(error);
            }
        });
    });
}



export const sum = (arr) => {
    if (arr.length < 1) return 0;

    return arr.reduce((a, b) => a + b, 0);
}

export const clamp = (value, min, max) => {
    return Math.max(min, Math.min(max, value));
}