import { useEffect, useRef, useState } from "react";
import { Cog } from "lucide-react";
import { fetchFormulaPreferences, updateFormulaPreferences } from "../utils/web-services";
import { toast } from "react-hot-toast";


export function FormulaPreferencesButton() {
    const settingDialog = useRef(null);
    const [formulaPreferences, setFormulaPreference] = useState(null);
    const [refresh, setReferesh] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await fetchFormulaPreferences();
            const preferences = !!result ? result.map((data) => {
                return {
                    ...data,
                    "VALUE": parseFloat(data["VALUE"])
                }
            }) : null;
            setFormulaPreference(preferences);
        })();
    }, [refresh]);

    const handleOpenModal = () => settingDialog.current.showModal();

    const handleOnChange = (event) => {
        setFormulaPreference((prev) => {
            const preferences = [...prev];
            const objIndex = preferences.findIndex(obj => obj["NAME"] === event.target.name);
            preferences[objIndex]["VALUE"] = parseFloat(event.target.value) || 0;
            return preferences;
        });
    }

    const handleSubmit = async () => {
        const response = await updateFormulaPreferences(formulaPreferences);
        response 
        ? toast.success("Formula Preferences has been updated.") 
        : toast.error("Formula Preferences update failed");
        setReferesh(prev => !prev);
        return;
    }

    return (
        <>
            <button className="btn btn-square" onClick={handleOpenModal}>
                <Cog />
            </button>
            <dialog ref={settingDialog} className="modal">
            <div className="modal-box w-11/12 max-w-5xl">
                <h3 className="font-bold text-lg">Formula Preferences</h3>
                <div className="py-4">
                    {
                        !!formulaPreferences 
                        ? (
                            <div className="flex flex-col gap-4">
                                {
                                    formulaPreferences.map((formulaPreference, index) => (
                                        <div key={index}>
                                            <label className="input input-bordered flex items-center gap-2">
                                             {formulaPreference["NAME"].replaceAll("_", " ")}
                                                <input className="grow text-end" placeholder="Pls fill up" type="number" name={formulaPreference["NAME"]} value={formulaPreference["VALUE"]} onChange={handleOnChange} />
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : <div className="flex-center mt-8"><div className="loading" /></div> 
                    }
                </div>
                <div className="modal-action">
                    <form method="dialog" className="flex flex-row gap-4">
                        <button className="btn btn-ghost">Cancel</button>
                        <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
            </dialog>
        </>
    )
}