import { Contrast } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { themeChange } from 'theme-change';

export function ThemeButton(props) {

    const btnRef = useRef(null);



    useEffect(() => {
        themeChange(false)
    }, [])

    return (
        <button ref={btnRef} data-toggle-theme="lofi,dark" data-act-class="ACTIVECLASS" className={"btn btn-ghost flex justify-center items-center " + props.className}>
            <Contrast />
        </button>
    )
}