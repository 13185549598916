import { ThemeButton } from "./theme-button";
import { NavItem } from "./nav-item";
import { Home, Upload, Skull, Database, LineChart, Anvil, Percent } from 'lucide-react';
import { Logo } from "../icons/logo";
import { LogoutButton } from "./logout-button";
import { useAuth } from "../contexts/auth-context";

export function NavBar() {

    const { role } = useAuth();

    return (
        <>
            <div className="flex flex-row lg:flex-col lg:sticky lg:h-screen top-0 left-0 p-4 bg-inherit overflow-visible lg:overflow-y-auto scrollbar-hide">
                <div className="hidden lg:block h-[48px]">
                    <NavItem title="Home" icon={<Logo h={48} w={48} />} to={"/"} className={"p-2 btn-link w-16 !border-0"} />
                </div>
                <div className="dropdown inline-block lg:hidden">
                    <div tabIndex={0} role="button" className="btn btn-square bg-base-100 shadow-none border-none">
                        <Logo className={"min-h-[48px] min-w-[48px]"} />
                    </div>
                    <ul tabIndex={0} className="dropdown-content z-[100] menu p-2 shadow bg-base-100 rounded-box w-52">
                        <NavItem title="Home" icon={<Home />} to={"/"} />
                        <NavItem title="Tester" icon={<Percent />} to={"/tester"} />
                        <NavItem title="Market" icon={<LineChart />} to={"/market"} />
                        <NavItem title="Data" icon={<Database />} to={"/data"} />
                        <NavItem title="Upload" icon={<Upload />} to={"/upload"} />
                        <NavItem title="Tools" icon={<Anvil />} to={"/tools"} />
                        <NavItem title="Danger" icon={<Skull />} to={"/danger"} className={"text-[#F92855]"} />
                    </ul>
                </div>

                <div className="hidden lg:flex divider m-0" />
                <div className="hidden lg:flex flex-1 lg:flex-none"/>
                <div className="hidden lg:flex flex-row lg:flex-col gap-4 overflow-x-auto overflow-y-visible scrollbar-hide">
                    <NavItem title="Home" icon={<Home />} to={"/"} />
                    <NavItem title="Tester" icon={<Percent />} to={"/tester"}/>
                    <NavItem title="Market" icon={<LineChart />} to={"/market"} />
                    <NavItem title="Data" icon={<Database />} to={"/data"} />
                    {
                        !!role && role === "ADMIN"
                        ? (
                            <>
                                <NavItem title="Upload" icon={<Upload />} to={"/upload"} />
                                <NavItem title="Tools" icon={<Anvil />} to={"/tools"} />
                                <NavItem title="Danger" icon={<Skull />} to={"/danger"} className={"text-[#F92855]"} />
                            </>
                        ) : null
                    }
                </div>
                <div className="flex-1 min-h-10" />
                <div className="divider m-0" />
                <ThemeButton />
                <LogoutButton />
            </div>
        </>
        
    )
}