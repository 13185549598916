import { DataUpload } from "../components/data-upload";
import { FileUpload } from "../components/file-upload";

export function Upload() {
    return (
        <>
            <div className="w-full h-screen">
                <div className="flex flex-col  p-4 min-h-screen h-auto lg:h-full overflow-hidden">
                    <h2 className="text-3xl font-bold mb-4">Upload Data</h2>
                    <div className="divider">Upload MYX code, sector, sub-sector</div>
                    <DataUpload />
                    <div className="divider">Upload EOD</div>
                    <FileUpload />
                </div>
            </div>
        </>
    )
}