import { formatNumberWithCommas } from "../../utils/common";
import EquityChart from "../equity-chart";

export function Overview({ performance }) {
    
    if(!performance) return <>No data found</>;

    const equityBalances = performance["EQUITY_BALANCES"];
    const initialCapital = performance["INIT_CAPITAL"];

    const netProfit = performance["TOTAL_NET_PROFIT"];
    const closedTrades = formatNumberWithCommas(performance["CLOSE_TRADE_COUNT"]);
    const percentageProfitable = performance["PERCENTAGE_PROFITABLE"] * 100;
    const profitFactor = performance["PROFIT_FACTOR"];
    const maxRunUp = performance["MAX_RUN_UP"];
    const maxDrawDown = performance["MAX_DRAW_DOWN"];
    const avgTrade = performance["AVG_TRADE"];
    const avgTradeBar = performance["AVG_TRADE_BAR"];

    const calcPercentage = (a, b) => ((a / b) * 100).toFixed(2);

    const netProfitPercentage = calcPercentage(netProfit, initialCapital);
    const maxRunUpPercentage = calcPercentage(maxRunUp, initialCapital);
    const maxDrawDownPercentage = calcPercentage(maxDrawDown, initialCapital);
    const avgTradePercentage = calcPercentage(avgTrade, initialCapital);


    return (
        <>
            <div className="w-full h-full p-4">
                <div className="flex flex-row gap-4 h-[5rem] mb-[1rem] overflow-x-auto scrollbar-hide">
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Net Profit</p>
                        <p className={`text-xl font-bold text-nowrap ${netProfit >= 0 ? "text-green" : "text-red"}`}>
                            $ {netProfit}
                            <span className="ml-4 text-sm">{netProfitPercentage}%</span>
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Total Closed Trades</p>
                        <p className="text-xl font-bold text-nowrap">
                            {closedTrades}
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Percent Profitable</p>
                        <p className={`text-xl font-bold text-nowrap ${percentageProfitable >= 50 ? "text-green" : "text-red"}`}>
                            {percentageProfitable}%
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Profit Factor</p>
                        <p className={`text-xl font-bold text-nowrap ${profitFactor >= 1 ? "text-green" : "text-red"}`}>
                            {profitFactor}
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Max RunUp</p>
                        <p className="text-xl font-bold text-nowrap text-green">
                            $ {maxRunUp}
                            <span className="ml-4 text-sm">{maxRunUpPercentage}%</span>
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Max Drawdown</p>
                        <p className="text-xl font-bold text-nowrap text-red">
                            $ {maxDrawDown}
                            <span className="ml-4 text-sm">{maxDrawDownPercentage}%</span>
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Avg. Trade</p>
                        <p className={`text-xl font-bold text-nowrap ${avgTrade >= 0 ? "text-green" : "text-red"}`}>
                            $ {avgTrade}
                            <span className="ml-4 text-sm">{avgTradePercentage}%</span>
                        </p>
                    </div>
                    <div className="divider divider-horizontal m-0" />
                    <div className="flex flex-col gap-2 flex-1 mr-8">
                        <p className="text-md font-bold text-nowrap">Avg. Bars in Trade</p>
                        <p className="text-xl font-bold text-nowrap">
                            {avgTradeBar}
                        </p>
                    </div>
                </div>
                <div className="h-[calc(100%-6rem)]">
                    <EquityChart data={equityBalances} init={initialCapital} />
                </div>
            </div>
        </>
    )
}