import { useMemo, useRef, useState } from "react";
import { ByDate } from "../components/screeners/by-date";
import { ByFormula } from "../components/screeners/by-formula";
import { FormulaPreferencesButton } from "../components/formula-preferences-button";
import { FormulaRefreshButton } from "../components/formula-refresh-button";
import { useAuth } from "../contexts/auth-context";
import { Filter, RotateCw, X } from "lucide-react";
import { ScreenerTable } from "../components/screeners/screener-table";
import { HKEX, MYX, NASDAQ, NYSE } from "../icons/exchange";
import { formatNumberWithCommas, isoStringToYYYYMMDD } from "../utils/common";
import DownloadCSVButton from "../components/download-csv-button";
import { ExportTickersButton } from "../components/export-ticker-button";

const icons = {
    "NASDAQ": <NASDAQ />,
    "NYSE": <NYSE />,
    "MYX": <MYX />,
    "HKEX": <HKEX />,
}

const defaultStats = () => {
    return {
        "EXCHANGES": {
            "NASDAQ": 0,
            "NYSE": 0,
            "MYX": 0,
            "HKEX": 0,
        },
        "FORMULAS": {},
        "DATE": {},
    }
};

export function Home() {
    const [screeners, setScreeners] = useState(null);

    const [loading, setLoading] = useState(false);

    const [stats, setStats] = useState(defaultStats());

    const { role } = useAuth();

    const [tab, setTab] = useState("date");

    const [exchange, setExchange] = useState("NASDAQ");

    const filterDialog = useRef(null);

    const [filters, setFilters] = useState([
        {
            name: "CLOSE",
            greater: "0",
            less: "0",
        }, {
            name: "SHARE_ISSUE",
            greater: "0",
            less: "0",
        }
    ]);

    const [refreshFilter, setRefreshFilter] = useState(false);


    const filterScreeners = useMemo(() => {
        let filteredData = screeners ?? [];

        const validFilters = filters.map((filter) => {
            return {
                name: filter.name,
                greater: parseFloat(filter.greater),
                less: parseFloat(filter.less),
            }
        }).filter((filter) => {
            const name = filter.name;
            const greater = filter.greater;
            const less = filter.less;
            return (
                !!name
                && (!!greater || !!less)
                && (!isNaN(greater) || !isNaN(less))
            );
        });

        if (validFilters.length > 0) {
            validFilters.forEach((filter) => {
                filteredData = filteredData.filter((d) => {
                    const filterName = filter.name;
                    const greater = filter.greater;
                    const less = filter.less;
                    const data = d[filterName];
                    if (!!greater && !!less) {
                        return data >= greater && data <= less;
                    } else if (!!greater && !less) {
                        return data >= greater
                    } else if (!greater && !!less) {
                        return data <= less
                    } else {
                        return true;
                    }
                });
            })
        };

        //calculate the statistics;

        const resultStats = defaultStats();

        filteredData.forEach((data) => {
            const dataFormula = data["FORMULA"];
            const dataExchange = data["EXCHANGE"];
            const dataDate = isoStringToYYYYMMDD(data["DATE"]);

            resultStats["EXCHANGES"][dataExchange] = (resultStats["EXCHANGES"][dataExchange] || 0) + 1;

            if(dataExchange === exchange) {
                resultStats["FORMULAS"][dataFormula] = (resultStats["FORMULAS"][dataFormula] || 0) + 1;
                resultStats["DATE"][dataDate] = (resultStats["DATE"][dataDate] || 0) + 1;
            }
        });
        setStats(resultStats);

        if (!!exchange) {
            filteredData = filteredData.filter((d) => d.EXCHANGE === exchange);
        }

        return filteredData;
    }, [screeners, exchange, refreshFilter]);


    const handleOnScreenersChange = (result) => {
        setScreeners(result);
    }
    const handleOpenModal = () => {
        filterDialog.current.showModal()
    }

    const filterOptions = [
        "TICK",
        "OPEN",
        "HIGH",
        "LOW",
        "CLOSE",
        "VOLUME",
        "SHARE_ISSUE",
    ];

    const handleAddFilter = () => {
        setFilters([...filters, {
            name: availableFilterOptions[0],
            greater: "0",
            less: "0",
        }]);
    };

    const handleRemoveFilter = (filterName) => {
        setFilters((prev) => prev.filter((f) => {
            return f.name !== filterName
        }))
    }

    const handleFilterInputChange = (index, event) => {
        const newFilters = [...filters];
        const name = event.target.name;
        const value = event.target.value;
        newFilters[index][name] = value;
        setFilters(newFilters);
    };

    const handleApplyFilter = () => {
        setRefreshFilter((prev) => !prev);
    }

    const handleResetFilter = () => {
        setFilters([
            {
                name: "CLOSE",
                greater: "0",
                less: "0",
            }, {
                name: "SHARE_ISSUE",
                greater: "0",
                less: "0",
            }
        ])
    }

    const getFileName = () => {
        if (tab === "date") {
            return isoStringToYYYYMMDD(filterScreeners[0]?.["DATE"]).replaceAll("-", "")
        } else if (tab === "formula") {
            return filterScreeners[0]?.["FORMULA"]
        } else {
            return null;
        }
    }

    const availableFilterOptions = useMemo(() => {
        const selectedFilter = filters.map((f) => f.name);
        return filterOptions.filter((option) => !selectedFilter.includes(option));
    }, [filters]);

    const tabs = {
        "date": <ByDate onChange={handleOnScreenersChange} />,
        "formula": <ByFormula onChange={handleOnScreenersChange} />,
    }

    const tabContent = tabs[tab];

    return (
        <>
            <div className="w-full min-h-screen">
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="flex flex-col space-y-4 overflow-auto p-4">
                        <h2 className="text-3xl font-bold mb-4">Home</h2>
                        <div className="flex flex-row align-center w-full gap-4">
                            <p className="flex-center">By:</p>
                            <div role="tablist" className="tabs tabs-boxed w-full">
                                {
                                    Object.keys(tabs).map((key, index) => (
                                        <div key={index} role="tab" className={`h-full tab ${key === tab ? "tab-active" : ""}`} onClick={() => {setTab(key)}}>{key.toUpperCase()}</div>
                                    ))
                                }
                            </div>
                            {
                                !!role && role === "ADMIN"
                                    ? (
                                        <>
                                            <FormulaRefreshButton />
                                            <FormulaPreferencesButton />
                                        </>
                                    ) : null
                            }
                        </div>
                        <div>
                            <div className="w-full overflow-visible flex flex-col">



                                <div className="w-full grid lg:grid-cols-4 lg:grid-rows-1 md:grid-cols-2 md:grid-rows-2 sm:grid-cols-1 sm:grid-rows-4 gap-4">
                                    <div className="h-28 w-full stats border-2 rounded-none resize-y overflow-y-auto scrollbar-hide">
                                        <div className="stat">
                                            <div className="flex flex-col gap-4">
                                                <div className="stat-title">Total Screeners:</div>
                                                <div className="stat-value">
                                                    {
                                                        loading ?
                                                            <div className="loading loading-bars loading-lg" />
                                                            : !!screeners
                                                                ? formatNumberWithCommas(filterScreeners.length)
                                                                : "?"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-28 w-full stats border-2 rounded-none resize-y overflow-y-auto scrollbar-hide">
                                        <div className="stat">
                                            <div className="flex flex-col">
                                                <div className="stat-title pb-2">Count by Exchange</div>
                                                {
                                                    loading
                                                        ? (<div className="loading loading-bars loading-lg" />)
                                                        : !!stats && Object.keys(stats.EXCHANGES).length > 0
                                                            ? (Object.keys(stats.EXCHANGES).map((exchange, index) => (
                                                                <div className="flex flex-row gap-4 align-center border-t-2 py-3" key={index}>
                                                                    {icons[exchange]} {exchange}
                                                                    <div className="flex-1" />
                                                                    {stats.EXCHANGES[exchange]}
                                                                </div>
                                                            )))
                                                            : stats
                                                                ? ("0")
                                                                : ("?")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-28 w-full stats border-2 rounded-none resize-y overflow-y-auto scrollbar-hide">
                                        <div className="stat">
                                            <div className="flex flex-col">
                                                {
                                                    tab === "formula"
                                                        ? (
                                                            <>
                                                                <div className="stat-title pb-2">Count by Date</div>
                                                                {
                                                                    loading
                                                                        ? (<div className="loading loading-bars loading-lg" />)
                                                                        : !!stats && Object.keys(stats.DATE).length > 0
                                                                            ? (Object.keys(stats.DATE).map((date, index) => (
                                                                                <div key={index} className="flex flex-row justify-between gap-2 py-3 border-t-2">
                                                                                    <p className="">
                                                                                        {date}
                                                                                    </p>
                                                                                    <p>
                                                                                        {stats.DATE[date]}
                                                                                    </p>
                                                                                </div>
                                                                            )))
                                                                            : stats
                                                                                ? ("0")
                                                                                : ("?")
                                                                }
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                <div className="stat-title pb-2">Count by Formula</div>
                                                                {
                                                                    loading
                                                                        ? (<div className="loading loading-bars loading-lg" />)
                                                                        : !!stats && Object.keys(stats.FORMULAS).length > 0
                                                                            ? (Object.keys(stats.FORMULAS).map((formula, index) => (
                                                                                <div key={index} className="flex flex-row justify-between gap-2 py-3 border-t-2">
                                                                                    <p className="">
                                                                                        {formula.replaceAll("_", " ")}
                                                                                    </p>
                                                                                    <p>
                                                                                        {stats.FORMULAS[formula]}
                                                                                    </p>
                                                                                </div>
                                                                            )))
                                                                            : stats
                                                                                ? ("0")
                                                                                : ("?")
                                                                }
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-28 w-full grid grid-cols-2 grid-rows-2 gap-4">
                                        <div className="col-span-2">
                                            {tabContent}
                                        </div>
                                        <div className="row-start-2">
                                            <ExportTickersButton data={filterScreeners} filename={`${exchange}_${getFileName()}`} />
                                        </div>
                                        <div className="row-start-2">
                                            <DownloadCSVButton data={filterScreeners} filename={`${exchange}_${getFileName()}`} />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <div className="flex flex-col lg:flex-row w-full justify-end gap-4">
                                        <button className={`btn flex-1 flex-nowrap ${exchange === "NASDAQ" ? "btn-primary" : ""}`} onClick={() => setExchange("NASDAQ")}>
                                            <div className="rounded-[100px] border overflow-hidden">
                                                <NASDAQ />
                                            </div>
                                            NASDAQ
                                        </button>
                                        <button className={`btn flex-1 flex-nowrap ${exchange === "NYSE" ? "btn-primary" : ""}`} onClick={() => setExchange("NYSE")}>
                                            <div className="rounded-[100px] border overflow-hidden">
                                                <NYSE />
                                            </div>
                                            NYSE
                                        </button>
                                        <button className={`btn flex-1 flex-nowrap ${exchange === "MYX" ? "btn-primary" : ""}`} onClick={() => setExchange("MYX")}>
                                            <div className="rounded-[100px] border overflow-hidden">
                                                <MYX />
                                            </div>
                                            MYX
                                        </button>
                                        <button className={`btn flex-1 flex-nowrap ${exchange === "HKEX" ? "btn-primary" : ""}`} onClick={() => setExchange("HKEX")}>
                                            <div className="rounded-[100px] border overflow-hidden">
                                                <HKEX />
                                            </div>
                                            HKEX
                                        </button>
                                        <button className="btn w-full md:btn-square" onClick={handleOpenModal}>
                                            <Filter />
                                        </button>
                                    </div>
                                    <div className="divider" />
                                    <ScreenerTable data={filterScreeners} />
                                </div>
                            </div>
                            {/* Filter Pop Up */}
                            <dialog ref={filterDialog} className="modal">
                                <div className="modal-box">
                                    <div className="flex flex-row justify-between items-center">
                                        <h3 className="font-bold text-lg">Filter</h3>
                                        <button className="btn btn-ghost btn-square" onClick={handleResetFilter}>
                                            <RotateCw />
                                        </button>
                                    </div>
                                    <div className="modal-action w-full">
                                        <div className="flex-center gap-4 w-full">
                                            {
                                                filters.map((filter, index) => {
                                                    return (
                                                        <div className="w-full flex flex-row items-center justify-between gap-2" key={`${filter.name}-${index}`}>
                                                            <select className="select" defaultValue={filter.name} name="name" onChange={(e) => handleFilterInputChange(index, e)}>
                                                                {
                                                                    filterOptions.map((option, index) => {
                                                                        return (
                                                                            <option key={`${option}-${index}`} value={option} disabled={!availableFilterOptions.includes(option)} selected={option}>
                                                                                {option.replaceAll("_", " ")}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <input type="number" step={0.1} placeholder=">=" defaultValue={0} value={filter.greater} min={0} className="input input-bordered w-24" name="greater" onChange={(e) => handleFilterInputChange(index, e)} />
                                                            <p>and</p>
                                                            <input type="number" step={0.1} placeholder="<=" defaultValue={0} value={filter.less} min={0} className="input input-bordered w-24" name="less" onChange={(e) => handleFilterInputChange(index, e)} />
                                                            <button className="btn btn-square btn-ghost" onClick={() => handleRemoveFilter(filter.name)}>
                                                                <X />
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <button className={"btn w-full " + (filters.length === filterOptions.length ? "hidden" : "")} onClick={handleAddFilter}>
                                                Add
                                            </button>
                                            <form method="dialog" className="w-full">
                                                <div className="flex flex-row gap-4 w-full">
                                                    <button className="flex-1 btn btn-link">Cancel</button>
                                                    <button className="flex-1 btn btn-primary" onClick={() => handleApplyFilter()} >Apply</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <form method="dialog" className="modal-backdrop">
                                    <button>close</button>
                                </form>
                            </dialog>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}