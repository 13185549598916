import { formatNumberWithCommas, sum } from "../utils/common";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from 'react-window';


export function SectorTrendTable({ data }) {

    if(!data || data.length < 1) return <div className="flex-center w-full h-full"> No data found </div>

    const headers = ["NAME", "UPTREND", "DOWNTREND", "UPTREND_VOLUME", "DOWNTREND_VOLUME"];

    const columnWidths = [500, 150, 150];


    const groupBySector = Object.groupBy(data, (d) => d.SECTOR || "? Unknow Sector");

    const tableData = [];

    Object.keys(groupBySector).forEach((key) => {
        tableData.push({
            "NAME": key,
            "UPTREND": sum(groupBySector[key].map((s) => parseInt(s.UPTREND || 0))),
            "DOWNTREND": sum(groupBySector[key].map((s) => parseInt(s.DOWNTREND || 0))),
            "UPTREND_VOLUME": sum(groupBySector[key].map((s) => parseInt(s.UPTREND_VOLUME || 0))),
            "DOWNTREND_VOLUME": sum(groupBySector[key].map((s) => parseInt(s.DOWNTREND_VOLUME || 0))),
            "TYPE": "SECTOR",
        });
        const formattedData = groupBySector[key].map((s) => {
            return {
                "TYPE": "INDUSTRY",
                "NAME": s.INDUSTRY ?? "Unknow Industry",
                "UPTREND": parseFloat(s.UPTREND),
                "DOWNTREND": parseFloat(s.DOWNTREND),
                "UPTREND_VOLUME": parseFloat(s.UPTREND_VOLUME),
                "DOWNTREND_VOLUME": parseFloat(s.DOWNTREND_VOLUME),
            }
        })
        tableData.push(...formattedData);
    });

    return (
        <>
            <AutoSizer>
                {({height, width}) => {
                    return (
                        <>
                            <Grid
                                columnCount={headers.length}
                                rowCount={tableData.length + 1 ?? 1}
                                height={height}
                                width={width}
                                columnWidth={(index) => columnWidths[index] ?? (width - sum(columnWidths)) / (headers.length - columnWidths.length)}
                                rowHeight={() => 50}
                            >
                                {
                                    ({columnIndex, rowIndex, style}) => {
                                        const header = headers[columnIndex];
                                        const row = tableData[rowIndex - 1];

                                        if (rowIndex === 0) {
                                            return (
                                                <div style={style} className="flex-center">{header}</div>
                                            )
                                        }

                                        return (
                                            <>
                                                <div style={style} className={`flex-center ${header === "NAME" ? "!items-start" : "!items-end"} ${row.TYPE === "SECTOR" && "bg-base-200 font-bold border-y-2"}`}>
                                                    <p className={`px-4`}>
                                                        {
                                                            header === "NAME"
                                                            ? row[header]
                                                            : (row[header] || 0).toLocaleString()
                                                        }
                                                    </p>
                                                </div>

                                            </>
                                        )
                                    }
                                }

                            </Grid>
                        </>
                    )
                }}
            </AutoSizer>
        </>
    )
}