import { useRef, useState } from "react";
import { NASDAQ, NYSE, MYX, HKEX } from "../icons/exchange"
import { calcEma, calcScreenerByFormula, fillUpMissingMarketTrends, fillUpMissingTick, uploadDataCsvFile } from "../utils/web-services";
import { formulaList, preventDefault } from "../utils/common"
import toast from "react-hot-toast";

export function FileUpload() {
    const fileInput = useRef(null);
    const [files, setFiles] = useState([]);
    const dialogRef = useRef(null);
    const [exchange, setExchange] = useState("NASDAQ");
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const newFiles = Array.from(event.target.files);
            setFiles(prevFiles => {
                const updatedFiles = [...prevFiles];

                newFiles.forEach(newFile => {
                    if (!prevFiles.some(file => file.name === newFile.name)) {
                        updatedFiles.push(newFile);
                    }
                });

                return updatedFiles;
            });
        }
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const eventMock = { target: { files: e.dataTransfer.files } };
        handleFileChange(eventMock);
    }

    const handleRemoveFile = (file) => {
        const newFiles = files.filter(x => x !== file);
        setFiles(newFiles);
    }


    const handleSelectButton = () => {
        fileInput.current?.click();
    }

    const handleClear = () => setFiles([]);

    const handleOpenDialog = () => {
        dialogRef.current?.showModal();
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            for (const file of files) {
                await toast.promise(uploadDataCsvFile(file, exchange), {
                    loading: `Uploading ${file.name}`,
                    success: `Upload ${file.name} Successful`,
                    error: (error) => error.message ?? "Something went wrong"
                });
            }
            handleClear();
            await toast.promise(calcEma(), {
                success: "Calculated the EMA value",
                loading: "Calculating EMA value",
                error: "Error on calculating EMA value"
            });
            await toast.promise(fillUpMissingMarketTrends(), {
                success: "Calculated the Market Trend",
                loading: "Calculating Market Trend",
                error: "Error on calculating Market Trend"
            });
            await toast.promise(fillUpMissingTick(), {
                success: "Calculated the Tick",
                loading: "Calculating Tick",
                error: "Error on calculating Tick"
            });
            const formulars = formulaList;

            for(const formula of formulars) {
                const formulaName = formula.replaceAll("_", " ");
                await toast.promise(calcScreenerByFormula(formula), {
                    loading: `Calculating ${formulaName}`,
                    success: `Calculated ${formulaName} SUCCESS`,
                    error: `Calculation ${formulaName} FAIL`
                });
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            return;
        }
    }



    return (
        <>
            {/* <ScreenLoading active={loading} /> */}
            <div className="flex flex-col space-y-4 h-full overflow-hidden">
                <div className="flex flex-col lg:flex-row h-full w-full min-h-0">
                    <div className={`w-full h-52 lg:h-full flex-center border border-dashed card`} onDragOver={preventDefault} onDrop={handleFileDrop}>
                        <button className="btn btn-ghost w-full h-full" onClick={handleSelectButton}>Select or drop your csv file here</button>
                        <input ref={fileInput} className="hidden" type="file" multiple={true} accept=".csv" onChange={handleFileChange} />
                    </div>
                    <div className="divider lg:divider-horizontal"></div>
                    <div className="w-full h-full card border overflow-hidden">
                        <div className="w-full overflow-auto overflow-y-scroll h-full">
                            <table className=" table table-zebra table-pin-rows">
                                <thead>
                                    <tr>
                                        <th>
                                            <button className="btn btn-ghost btn-sm">
                                                NAME
                                            </button>
                                        </th>
                                        <th className="w-12">
                                            <button className="w-full btn btn-sm btn-ghost" onClick={handleClear}>
                                                REMOVE
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        files.length > 0
                                            ? (
                                                files.map((file, index) => (
                                                    <tr key={index}>
                                                        <td>{file.name}</td>
                                                        <td className="flex-center h-full">
                                                            <button className="btn btn-square btn-sm btn-ghost" onClick={() => handleRemoveFile(file)}>
                                                                x
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : (
                                                <tr className="text-center opacity-50">
                                                    <th colSpan={2}>No file was selected</th>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row w-full justify-end space-y-4 lg:space-y-0 lg:space-x-4">
                    <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NASDAQ" ? "btn-primary" : ""}`} onClick={() => setExchange("NASDAQ")}>
                        <div className="rounded-[100px] border overflow-hidden">
                            <NASDAQ />
                        </div>
                        NASDAQ
                    </button>
                    <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NYSE" ? "btn-primary" : ""}`} onClick={() => setExchange("NYSE")}>
                        <div className="rounded-[100px] border overflow-hidden">
                            <NYSE />
                        </div>
                        NYSE
                    </button>
                    <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "MYX" ? "btn-primary" : ""}`} onClick={() => setExchange("MYX")}>
                        <div className="rounded-[100px] border overflow-hidden">
                            <MYX />
                        </div>
                        MYX
                    </button>
                    <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "HKEX" ? "btn-primary" : ""}`} onClick={() => setExchange("HKEX")}>
                        <div className="rounded-[100px] border overflow-hidden">
                            <HKEX />
                        </div>
                        HKEX
                    </button>
                    <button className=" btn btn-primary bottom-4 right-4" onClick={handleOpenDialog} disabled={files.length < 1}>
                        Upload {files.length <= 0 ? "" : files.length === 1 ? "1 file" : `${files.length} files`}
                    </button>
                </div>
            </div>
            <dialog ref={dialogRef} className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Confirmation:</h3>
                    <p>This action cannot be undo and please dont turn off this window when uploading. </p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="m-2 btn btn-outline">Close</button>
                            <button className="m-2 btn btn-primary" onClick={handleSubmit}>Submit</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    );
}