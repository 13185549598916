import { useEffect, useState } from "react";
import { MarketTrendChart } from "../components/market-trend-chart";
import { SectorTrendTable } from "../components/sector-trend-table";
import { fetchMarketTrendsByExchange, fetchSectorTrendsByExchangeAndDate } from "../utils/web-services";
import { HKEX, MYX, NASDAQ, NYSE } from "../icons/exchange";
import Datepicker from "react-tailwindcss-datepicker";
import { AreaChart, Table2 } from "lucide-react";
import DownloadCSVButton from "../components/download-csv-button";
export function Market() {
    const [marketTrend, setMarketTrend] = useState([]);
    const [exchange, setExchange] = useState("NASDAQ");
    const [loading, setLoading] = useState(false);

    const [sectorTrend, setSectorTrend] = useState([]);

    const [showChartOrTable, setShowChartOrTable] = useState(false);

    const toogleShowChartOrTable = () => setShowChartOrTable(!showChartOrTable);

    const [date, setDate] = useState({
        startDate: "2023-02-02",
        endDate: "2023-02-02",
    });

    const handleDateChange = (value) => setDate(value);

    useEffect(() => {
        (async () => {
            const result = await fetchMarketTrendsByExchange(exchange);
            setMarketTrend(result);
        })();
    }, [exchange]);

    useEffect(() => {
        setSectorTrend([]);
        (async () => {
            if (!!date.startDate) {
                const result = await fetchSectorTrendsByExchangeAndDate(exchange, date.startDate);
                setSectorTrend(result);
            }
        })()
    }, [exchange, date])

    return (
        <>
            <div className="w-full h-screen max-h-full lg:max-h-screen">
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="flex flex-col space-y-4 overflow-auto p-4">
                        <h2 className="text-3xl font-bold mb-4">Market</h2>
                        <div className="flex flex-col lg:flex-row w-full justify-end space-y-4 lg:space-y-0 lg:space-x-4">
                            <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NASDAQ" ? "btn-primary" : ""}`} onClick={() => setExchange("NASDAQ")}>
                                <div className="rounded-[100px] border overflow-hidden">
                                    <NASDAQ />
                                </div>
                                NASDAQ
                            </button>
                            <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NYSE" ? "btn-primary" : ""}`} onClick={() => setExchange("NYSE")}>
                                <div className="rounded-[100px] border overflow-hidden">
                                    <NYSE />
                                </div>
                                NYSE
                            </button>
                            <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "MYX" ? "btn-primary" : ""}`} onClick={() => setExchange("MYX")}>
                                <div className="rounded-[100px] border overflow-hidden">
                                    <MYX />
                                </div>
                                MYX
                            </button>
                            <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "HKEX" ? "btn-primary" : ""}`} onClick={() => setExchange("HKEX")}>
                                <div className="rounded-[100px] border overflow-hidden">
                                    <HKEX />
                                </div>
                                HKEX
                            </button>
                            <div className="divider lg:divider-horizontal my-2 lg:mx-2" />
                            <div className="btn z-10" id="calendar">
                                <Datepicker
                                    primaryColor="blue"
                                    inputClassName={"min-h-12 bg-transparent w-full text-start text-inherit focus:outline-none"}
                                    useRange={false}
                                    asSingle={true}
                                    value={date}
                                    onChange={handleDateChange}
                                    displayFormat={"MMM DD, YYYY"}
                                />
                            </div>
                            <div>
                                {
                                    showChartOrTable
                                        ? <DownloadCSVButton data={sectorTrend} filename={(!!date.endDate ? date.endDate.replaceAll("-", "") : "no_date") + "_sector_trend"} />
                                        : <DownloadCSVButton data={marketTrend} filename={exchange + "_market_trend"} />
                                }
                            </div>
                            <button className="btn btn-square w-full lg:w-12" onClick={toogleShowChartOrTable}>
                                {
                                    showChartOrTable
                                        ? <AreaChart />
                                        : <Table2 />
                                }
                            </button>
                        </div>
                        <div className="divider" />
                        <div className="h-[95vh] border">
                            {
                                showChartOrTable
                                    ? <SectorTrendTable data={sectorTrend} />
                                    : <MarketTrendChart data={marketTrend} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}