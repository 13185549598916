import { EodDataToCsv } from "../components/tools/eoddata-to-csv";
import { MapCsvCategory } from "../components/tools/map-csv-category";

export function Tools() {
    return (
        <>
            <div className="w-full min-h-screen ">
                <div className="flex flex-col  p-4 h-full overflow-hidden gap-4">
                    <h2 className="text-3xl font-bold">Tools</h2>
                    <div>
                        <div className="divider">
                            eoddata.com txt file convert to csv
                        </div>
                        <EodDataToCsv />
                    </div>
                    <div>
                        <div className="divider">
                            fill up category data
                        </div>
                        <MapCsvCategory />
                    </div>
                </div>
            </div>
        </>
    )
}