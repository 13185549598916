import { Link } from "react-router-dom";

export function NotFound() {
    return (
        <>
            <div className="w-full h-full flex-center gap-4">
                404 not found
                <Link to={"/"}>
                    <button className="btn btn-primary">Back to HomePage</button>
                </Link>
            </div>
        </>
    )
}