import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from 'react-window';
export function ListOfTrades({ trades }) {
    if (!trades) return <>No data found</>

    const headers = [
        "Trade#",
        "STOCK",
        "SIGNAL",
        "DATE",
        "PRICE",
        "CONTRACTS",
        "PROFIT",
        "RUN-UP",
        "DRAWDOWN",
        "REASON",
    ];

    const sortedTrades = trades.sort((a, b) => a["ID"] - b["ID"]);

    const columnWidths = [
        100, 200, 100, 150, 100, 180, 200, 200, 200, 300
    ]


    return (
        <>
            <div className="block h-[90vh] w-full overflow-visible">
                <AutoSizer>
                    {({ height, width }) => {
                        return (
                            <Grid
                                height={height}
                                width={width}
                                rowCount={sortedTrades.length + 1}
                                columnCount={headers.length}
                                columnWidth={(i) => columnWidths[i] ?? 300}
                                rowHeight={(i) => i === 0 ? 50 : 100}
                            >
                                {({ columnIndex, rowIndex, style }) => {
                                    const header = headers[columnIndex];

                                    if (rowIndex === 0) {
                                        return (
                                            <p style={style} className="flex-center border-b">{header}</p>
                                        )
                                    } else {
                                        const trade = sortedTrades[rowIndex - 1];
                                        const ticker = `${trade["EXCHANGE"]}:${trade["TICKER"]}` ?? "?";

                                        switch (header) {
                                            case "Trade#":
                                                return (
                                                    <p style={style} className="flex-center border-b">{trade["ID"] ?? "?"}</p>
                                                );
                                            case "STOCK":
                                                return (
                                                    <div style={style} className="flex-center border-b">
                                                        <a className="btn btn-link p-0 select-text" href={`https://www.tradingview.com/chart/?symbol=${ticker}`} target="_blank" rel="noreferrer">
                                                            {ticker}
                                                        </a>
                                                    </div>
                                                );
                                            case "SIGNAL":
                                                return (
                                                    <div style={style} className="flex-center border-b">
                                                        <div className="flex flex-col w-full">
                                                            <p className="text-center">sell</p>
                                                            <div className="divider m-0 p-0" />
                                                            <p className="text-center">buy</p>
                                                        </div>
                                                    </div>
                                                );
                                            case "DATE":
                                                return (
                                                    <div style={style} className="flex-center border-b">
                                                        <div className="flex flex-col w-full">
                                                            <p className="text-center text-nowrap">{trade["SELL_DATE"] ?? "--"}</p>
                                                            <div className="divider m-0 p-0" />
                                                            <p className="text-center text-nowrap">{trade["BUY_DATE"]}</p>
                                                        </div>
                                                    </div>
                                                );
                                            case "PRICE":
                                                return (
                                                    <div style={style} className="flex-center border-b">
                                                        <div className="flex flex-col w-full">
                                                            <p className="text-end">{trade["SELL_PRICE"] ?? "--"}</p>
                                                            <div className="divider m-0 p-0" />
                                                            <p className="text-end">{trade["BUY_PRICE"]}</p>
                                                        </div>
                                                    </div>
                                                );
                                            case "CONTRACTS":
                                                return (
                                                    <p style={style} className="flex-center border-b p-4 w-full text-end !items-end">{trade["CONTRACTS"] ?? "--"}</p>
                                                );
                                            case "PROFIT":
                                                const profit = trade["PROFIT"];
                                                const profitStyling = !profit
                                                    ? "!text-center"
                                                    : profit >= 0
                                                        ? "text-green"
                                                        : "text-red";

                                                const profitPercentage = !profit ? "" : ((profit / trade["COST"]) * 100).toFixed(2) + "%";

                                                return (
                                                    <p style={style} className={`flex-center border-b p-4 w-full text-end !items-end ${profitStyling}`}>
                                                        {!!profit ? "$ " + profit.toFixed(2) : "--"}
                                                        <p className="text-xs">{profitPercentage}</p>
                                                    </p>
                                                );
                                            case "RUN-UP":
                                                const runUp = trade["RUN_UP"];
                                                const runUpStyling = !runUp
                                                    ? "!text-center"
                                                    : runUp >= 0
                                                        ? "text-green"
                                                        : "text-red";

                                                const runUpPercentage = !runUp ? "" : (((runUp -  trade["BUY_PRICE"])/ trade["BUY_PRICE"]) * 100).toFixed(2) + "%";

                                                return (
                                                    <p style={style} className={`flex-center border-b p-4 w-full text-end !items-end ${runUpStyling}`}>
                                                        {!!runUp ? "$ " + runUp.toFixed(2) : "--"}
                                                        <p className="text-xs">{runUpPercentage}</p>
                                                    </p>
                                                );
                                            case "DRAWDOWN":
                                                const drawDown = trade["DRAW_DOWN"];
                                                const drawDownStyling = !drawDown
                                                    ? "!text-center"
                                                    : drawDown >= 0
                                                        ? "text-green"
                                                        : "text-red";

                                                const drawDownPercentage = !drawDown ? "" : (((drawDown -  trade["BUY_PRICE"])/ trade["BUY_PRICE"]) * 100).toFixed(2) + "%";

                                                return (
                                                    <p style={style} className={`flex-center border-b p-4 w-full text-end !items-end ${drawDownStyling}`}>
                                                        {!!drawDown ? "$ " + drawDown.toFixed(2) : "--"}
                                                        <p className="text-xs">{drawDownPercentage}</p>
                                                    </p>
                                                );

                                            case "REASON":
                                                return (
                                                    <p style={style} className="flex-center border-b p-4 w-full text-end !items-end">{trade["REASON"] ?? "HOLDING"}</p>
                                                );
                                            default:
                                                return (<p>??</p>)
                                        }
                                    }

                                }}
                            </Grid>
                        )
                    }}
                </AutoSizer>
            </div>
        </>
    )
}