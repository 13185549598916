import { XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart } from 'recharts';
import AutoSizer from "react-virtualized-auto-sizer";

const EquityChart = ({ data, init }) => {
    const sortedData = Object.keys(data).map(date => {
        if(!data[date]) return undefined;
        return ({
            date,
            equity: data[date]
        })
    }).sort((a, b) => new Date(a.date) - new Date(b.date));

    
    const uptrendColorCode = "#26a69a";
    const downtrendColorCode = "#ef5350";
    const dataArray = [{
        "date": "start",
        "equity": init ?? 0 
    }, ...sortedData];


    const gradientOffset = () => {
        const dataMax = Math.max(...dataArray.map((i) => i.equity));
        const dataMin = Math.min(...dataArray.map((i) => i.equity));        

        if (dataMax <= init) {  
            return 0;   
        }
        if (dataMin >= init) {
            return 1;
        }

        return (dataMax - init) / (dataMax - dataMin);
    };

    const off = gradientOffset();


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <table className="table bg-base-100">
                        <tbody>
                            <tr>
                                <th>DATE</th>
                                <td className="text-end">{(payload[0].payload["date"])}</td>
                            </tr>
                            <tr>
                                <th>EQUITY</th>
                                <td className="text-end">{(payload[0].payload["equity"]).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            );
        }

        return null;
    };


    return (
        <AutoSizer>
             {({ height, width }) => {
                return (
                    <AreaChart
                        width={width}
                        height={height}
                        data={dataArray}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                            <linearGradient id="areaColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset={0} stopColor={uptrendColorCode} stopOpacity={1} />
                                <stop offset={off} stopColor={uptrendColorCode} stopOpacity={0.2} />
                                <stop offset={off} stopColor={downtrendColorCode} stopOpacity={0.2} />
                                <stop offset={1} stopColor={downtrendColorCode} stopOpacity={1} />
                            </linearGradient>   
                            <linearGradient id="strokeColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset={off} stopColor={uptrendColorCode} stopOpacity={1} />
                                <stop offset={off} stopColor={downtrendColorCode} stopOpacity={1} />
                            </linearGradient>   
                        </defs>
                        <Area type="linear" dataKey="equity" stroke="url(#strokeColor)" strokeWidth={1} fill="url(#areaColor)" baseValue={init ?? 0} />
                    </AreaChart>
                )
             }}
        </AutoSizer>
    );
};

export default EquityChart;
