import { useRef } from "react";

export function SimpleDialog({ onSubmit, title, content = "", buttonTxt = "Open" }) {
    const ref = useRef(null);
    return (
        <>
            <button className="btn" onClick={() => ref.current.showModal()}>{buttonTxt}</button>
            <dialog ref={ref} className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">{title}</h3>
                    <div className="my-4">{content}</div>
                    <div className="modal-action">
                        <form method="dialog" className="flex flex-row space-x-2">
                            <button className="btn btn-outline">Close</button>
                            <button className="btn btn-primary" onClick={onSubmit}>Submit</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    )
}