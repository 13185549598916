import React, { useContext, useEffect, useState } from 'react';
import { performLogin, performLogout } from '../utils/web-services';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {

  const [sessionId, setSessionId] = useState(() => {
    return localStorage.getItem('sessionId') || null;
  });
  
  const [role, setRole] = useState(() => {
    return localStorage.getItem('role') || null;
  });

  const [loading, setLoading] = useState(false);

  const login = async (email, password) => {
    setLoading(true);
    try {
        const response = await performLogin(email, password);
        const newSessionId = response.sessionId;
        const newRole = response.role;
        setSessionId(newSessionId ?? null);
        setRole(newRole);
    } catch (error) {
        setSessionId(null);
        setRole(null)
        console.error(error);
    } finally {
        setLoading(false);
    }

  }
  const logout = async () => {
    setLoading(true);
    try {
        await performLogout();
    } catch (error) {
        console.error(error);
    } finally {
        setSessionId(null);
        setRole(null);
        setLoading(false);
    }
  }

  useEffect(() => {
    if (sessionId) {
        localStorage.setItem('sessionId', sessionId);
    } else {
        localStorage.removeItem('sessionId');
    }
    if (role) {
        localStorage.setItem('role', role);
    } else {
        localStorage.removeItem('role');
    }
  }, [sessionId, role]);

  return (
    <AuthContext.Provider value={{ sessionId, role, login, logout }}>
        {loading ? (
            <div className='h-screen fixed top-0 r-0 w-screen flex-center'><div className='loading loading-lg' /></div>
        ) : children}
    </AuthContext.Provider>
  );
}
