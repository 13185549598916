import { useEffect, useState } from "react";
import { getServerConnectionStatus } from "../utils/web-services";
import { useAuth } from "../contexts/auth-context";

export function ServerStatus() {
    const [status, setStatus] = useState(null);
    const { logout } = useAuth();

    useEffect(() => {
        (async () => {
            const response = await getServerConnectionStatus();
            if (response.status === 401) {
                localStorage.removeItem("sessionId");
                return await logout();
            };
            setStatus(response.ok);
        })();
    }, [])

    const statusClass = status === null
        ? "bg-gray-500"
        : status
            ? "bg-green-500"
            : "bg-red-500";


    const statusMessage = status === null
        ? "Loading"
        : status
            ? "Server connected"
            : "Server connect failed";


    return (
        <>
            <div className={`h-4 w-4 tooltip tooltip-left`} data-tip={statusMessage}>
                <div className={`h-full w-full rounded-3xl border-2 ${statusClass}`} />
            </div>
        </>
    )
}