import { saveAs } from 'file-saver';
import { arrayToFiavestImportList, arrayToMooMooImportList, arrayToTVImportList } from '../utils/common';
import { useRef } from 'react';
import toast from 'react-hot-toast';
import { FaFileSignature } from "react-icons/fa";

export function ExportTickersButton({data, filename}) {

    const options = [
        "MooMoo",
        "Fiavest",
        "TradingView",
    ];
    const settingDialog = useRef(null);

    const handleOpenModal = () => settingDialog.current.showModal();


  const handleSubmit = (format) => {
    if(!data || data.length < 1) {
        toast.error("No data found");
        return;
    }
    let formmatedData;
    let filterData;
    let formattedFilename;

    try {
        switch (format) {
            case "Fiavest":
                filterData = data.filter((row) => !!row["STOCK_CODE"] && !!row["TICKER"]);
                formmatedData = arrayToFiavestImportList(filterData);
                formattedFilename = !filename ? "fiavest.txt" : filename.replaceAll("-", "") + "_fiavest.csv"
                break;
            case "MooMoo":
                filterData = data.filter((row) => !!row["MOOMOO_CODE"] && !!row["TICKER"]);
                formmatedData = arrayToMooMooImportList(filterData);
                formattedFilename = !filename ? "moomoo.ebk" : filename.replaceAll("-", "") + "_moomoo.ebk"
                break;
            case "TradingView":
                filterData = data.filter((row) => !!row["EXCHANGE"] && !!row["TICKER"]);
                formmatedData = arrayToTVImportList(filterData);
                formattedFilename = !filename ? "tv.txt" : filename.replaceAll("-", "") + "_tv.txt"
                break;
            default:
                toast.error("Something went wrong")
                return;
        }
        if(filterData.length > 0) {
            toast(`${filterData.length} valid data found, start downloading ${formattedFilename}`);
            const blob = new Blob([formmatedData], { type: 'text/plain;charset=utf-8;' });
            saveAs(blob, formattedFilename);
        } else {
            toast.error("Invalid data")
        }
    } catch (err) {
      console.error('Error generating TXT file:', err);
      toast.error("Something went wrong");
    }
  };
    return (
        <>
        <button className="btn w-full h-full" onClick={handleOpenModal}>
            < FaFileSignature size={22} />
        </button>
        <dialog ref={settingDialog} className="modal">
            <div className="modal-box">
                <h3 className="font-bold text-lg">Export as</h3>
                <div className="modal-action w-full">
                    <form method="dialog" className="w-full">
                        <div className="flex flex-col gap-4 w-full">
                            {
                                options.map((formula, index) => (
                                    <button key={index} className="btn w-full" onClick={() => handleSubmit(formula)}>
                                        {formula}
                                    </button>
                                ))
                            }
                            <button className="btn btn-primary">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
            </dialog>
        </>
    )
}