import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Tooltip, Area, Bar, Line, Brush } from "recharts";
import { formatNumberWithCommas, isoStringToYYYYMMDD } from "../utils/common";
export function MarketTrendChart({ data }) {

    if(!data || data.length < 1) return <div className="flex-center w-full h-full"> No data found </div>

    const chartData = data.map((trendData) => {
        return {
            DATE: isoStringToYYYYMMDD(trendData["DATE"]),
            UPTREND: trendData["UPTREND"],
            DOWNTREND: trendData["DOWNTREND"],
            UPTREND_VOLUME: trendData["UPTREND_VOLUME"],
            DOWNTREND_VOLUME: trendData["DOWNTREND_VOLUME"],
        }
    })

    const uptrendColorCode = "#26a69a";
    const downtrendColorCode = "#ef5350";

    const len = chartData.length;


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <table className="table bg-base-100">
                        <thead>
                            <tr>
                                <th colSpan={2}>{(payload[0].payload["DATE"])}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>UPTREND</th>
                                <td className="text-end">{formatNumberWithCommas(payload[0].payload["UPTREND"])}</td>
                            </tr>
                            <tr>
                                <th>DOWNTREND</th>
                                <td className="text-end">{formatNumberWithCommas(payload[0].payload["DOWNTREND"])}</td>
                            </tr>
                            <tr>
                                <th>UPTREND_VOLUME</th>
                                <td className="text-end">{formatNumberWithCommas(payload[0].payload["UPTREND_VOLUME"])}</td>
                            </tr>
                            <tr>
                                <th>DOWNTREND_VOLUME</th>
                                <td className="text-end">{formatNumberWithCommas(payload[0].payload["DOWNTREND_VOLUME"])}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            );
        }

        return null;
    };

    return (
        <>
            <ResponsiveContainer width={'100%'} minHeight={"85vh"}>
                <ComposedChart data={chartData}>
                    <defs>
                        <linearGradient id="colorUptrend" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={uptrendColorCode} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={uptrendColorCode} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorDowntrend" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={downtrendColorCode} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={downtrendColorCode} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="DATE" domain={["dataMin", "dataMax"]}   />
                    <YAxis yAxisId={"left"} allowDataOverflow={true} hide={true} />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" domain={[0, (dataMax) => (parseInt(dataMax) * 5)]} hide={true} />

                    {/* <Tooltip labelStyle={{color: "#121212"}} /> */}
                    <Tooltip content={<CustomTooltip />} />
                    <Area isAnimationActive={false} yAxisId={"left"} type="natural" dataKey="UPTREND" stroke={uptrendColorCode} fill={"url(#colorUptrend)"} />
                    <Area isAnimationActive={false} yAxisId={"left"} type="natural" dataKey="DOWNTREND" stroke={downtrendColorCode} fill={"url(#colorDowntrend)"} />

                    <Line isAnimationActive={false} yAxisId={"left"} type="natural" dataKey="UPTREND" stroke={uptrendColorCode} />
                    <Line isAnimationActive={false} yAxisId={"left"} type="natural" dataKey="DOWNTREND" stroke={downtrendColorCode} />

                    <Bar stackId={"volume"} yAxisId="right" dataKey="UPTREND_VOLUME" fill={uptrendColorCode} />
                    <Bar stackId={"volume"} yAxisId="right" dataKey="DOWNTREND_VOLUME" fill={downtrendColorCode} />

                    <Brush name='date' dataKey="date" height={20} startIndex={len - 13} endIndex={len - 1} />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}