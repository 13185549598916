import { formatNumberWithCommas } from "../../utils/common";
export function PerformanceSummary({ performance }) {

    const currency = "currency";
    const count = "count";
    const percentage = "percentage";
    const float = "float";


    const headers = [
        {
            label: "Net Profit",
            key: "TOTAL_NET_PROFIT",
            type: currency,
        },
        {
            label: "Gross Profit",
            key: "TOTAL_GROSS_PROFIT",
            type: currency,
        },
        {
            label: "Gross Loss",
            key: "TOTAL_GROSS_LOSS",
            type: currency,
        },
        {
            label: "Max Run-up",
            key: "MAX_RUN_UP",
            type: currency,
        },
        {
            label: "Max Drawdown",
            key: "MAX_DRAW_DOWN",
            type: currency,
        },
        {
            label: "Total Closed Trade",
            key: "CLOSE_TRADE_COUNT",
            type: count,
        },
        {
            label: "Total Open Trade",
            key: "OPEN_TRADE_COUNT",
            type: count,
        },
        {
            label: "Number Winning Trades",
            key: "WINNING_TRADE_COUNT",
            type: count,
        },
        {
            label: "Number Losing Trades",
            key: "LOSING_TRADE_COUNT",
            type: count,
        },
        {
            label: "Profit Factor",
            key: "PROFIT_FACTOR",
            type: float,
        },
        {
            label: "Percent Profitable",
            key: "PERCENTAGE_PROFITABLE",
            type: percentage,
        },
        {
            label: "Commission Paid",
            key: "TOTAL_COMMISSION_PAID",
            type: currency,
        },
        {
            label: "Avg. Trade",
            key: "AVG_TRADE",
            type: currency,
        },
        {
            label: "Avg. Winning Trade",
            key: "AVG_WINNING_TRADE",
            type: currency,
        },
        {
            label: "Avg. Losing Trade",
            key: "AVG_LOSING_TRADE",
            type: currency,
        },
        {
            label: "Ratio Avg.Win / Avg.Loss",
            key: "RATIO_AVG_WIN_AVG_LOSS",
            type: float,
        },
        {
            label: "Largest Winning Trade",
            key: "LARGEST_WINNING_TRADE",
            type: currency,
        },
        {
            label: "Largest Losing Trade",
            key: "LARGEST_LOSING_TRADE",
            type: currency,
        },
        {
            label: "Avg. Bar in Trade",
            key: "AVG_TRADE_BAR",
            type: float,
        },
        {
            label: "Avg. Bar in Winning Trade",
            key: "AVG_WINNING_BAR",
            type: float,
        },
        {
            label: "Avg. Bar in Losing Trade",
            key: "AVG_LOSSING_BAR",
            type: float,
        },
    ];
    return (
        <>
            <div className="h-full w-full relative">
                <table className="table text-lg">
                    <thead>
                        <tr className="border-b border-current">
                            <th className="text-center">Title</th>
                            <th className="text-center">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            headers.map((header, index) => {
                                const label = header.label;
                                const key = header.key;
                                const type = header.type;
                                const value = (
                                    type === percentage 
                                    ? performance[key] * 100 
                                    : type === "count"
                                        ? formatNumberWithCommas(performance[key])
                                        : performance[key]
                                ) ?? "??";

                                
                                return (
                                    <tr key={index} className={`${index === headers.length - 1 ? "" : "border-b border-current"} hover`}>
                                        <th className="text-nowrap">{label}</th>
                                        <td className="text-end text-nowrap">
                                            {type === currency && "$ "}
                                            {value}
                                            {type === percentage && "%"}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}