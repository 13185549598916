import { Children, useEffect, useState } from "react";
import { fetchEodByDateAndExchange } from "../utils/web-services";
import dayjs from "dayjs";
import Datepicker from "react-tailwindcss-datepicker";
import { HKEX, MYX, NASDAQ, NYSE } from "../icons/exchange";
import { ChevronDown, ChevronUp } from "lucide-react";
import toast from "react-hot-toast";
import { formatPrice, isoStringToYYYYMMDD } from "../utils/common";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from 'react-window';
import DownloadCSVButton from "./download-csv-button";

export function EodTable() {
    const today = isoStringToYYYYMMDD(new Date().toISOString());

    const [eod, setEod] = useState([]);
    const [exchange, setExchange] = useState("NASDAQ");
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [orderBy, setOrderBy] = useState("asc");
    const [date, setDate] = useState({
        startDate: today,
        endDate: today,
    });

    const headers = [
        "EXCHANGE",
        "TICKER",
        "OPEN",
        "HIGH",
        "LOW",
        "CLOSE",
        "VOLUME",
        "TICK",
        "SHARE_ISSUE",
        "MARKET_CAPITALIZATION",
        "EMA3",
        "EMA5",
        "EMA6",
        "EMA9",
        "EMA12",
        "EMA30",
        "EMA50",
    ];

    const columnWidths = [
        60,
        130,
        130,
        130,
        130,
        130,
        130,
        200,
        130,
        300,
        300,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
    ];

    const handleDateChange = (value) => setDate(value);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (!!date.endDate) {
                const result = await fetchEodByDateAndExchange(date.endDate, exchange);
                setEod(result);
            }
            setLoading(false);
        })()
    }, [date, exchange]);


    useEffect(() => {
        if(eod.length > 0) {
            if(!!sortBy) {
                if (orderBy === "asc") {
                    setEod(prev => [...prev].sort((a, b) => {
                        if (sortBy === "TICKER") {
                            return a[sortBy] > b[sortBy] ? 1 : -1;
                        } else {
                            return a[sortBy] - b[sortBy];
                        }
                    }));
                } else {
                    setEod(prev => [...prev].sort((a, b) => {
                        if (sortBy === "TICKER") {
                            return a[sortBy] < b[sortBy] ? 1 : -1;
                        } else {
                            return b[sortBy] - a[sortBy];
                        }
                    }));
                }
            }
        }
    }, [sortBy, orderBy]);

    return (
        <>
            <div className="flex flex-col pb-12">
                <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col md:flex-row w-full justify-end space-y-4 md:space-y-0 md:space-x-4">
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NASDAQ" ? "btn-primary" : ""}`} onClick={() => setExchange("NASDAQ")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                <NASDAQ />
                            </div>
                            NASDAQ
                        </button>
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NYSE" ? "btn-primary" : ""}`} onClick={() => setExchange("NYSE")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                <NYSE />
                            </div>
                            NYSE
                        </button>
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "MYX" ? "btn-primary" : ""}`} onClick={() => setExchange("MYX")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                <MYX />
                            </div>
                            MYX
                        </button>
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "HKEX" ? "btn-primary" : ""}`} onClick={() => setExchange("HKEX")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                <HKEX />
                            </div>
                            HKEX
                        </button>
                    </div>
                    <div className="divider md:divider-horizontal my-2 md:mx-2" />
                    <div className="btn z-10" id="calendar">
                        <Datepicker
                            primaryColor="blue"
                            inputClassName={"min-h-12 bg-transparent w-full text-start text-inherit focus:outline-none"}
                            useRange={false}
                            asSingle={true}
                            value={date}
                            onChange={handleDateChange}
                            displayFormat={"MMM DD, YYYY"}
                        />
                    </div>
                    <div className="ml-0 mt-2 md:ml-2 md:mt-0">
                        <DownloadCSVButton data={eod} filename={exchange + "_" + (!!date.endDate ? date.endDate.replaceAll("-", "") : "no_date")} excludeHeaders={["ID", "STOCK_ID"]} />
                    </div>
                </div>
                <div className="divider" />
                <div className="h-[95vh]">
                    <div className="w-full h-full overflow-visible border">
                    {
                        !!eod  && eod.length > 0 
                        ? (
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <>
                                            <Grid
                                                columnCount={headers.length + 1}
                                                rowCount={eod.length + 1 ?? 1}
                                                height={height}
                                                width={width}
                                                columnWidth={(i) => columnWidths[i]}
                                                rowHeight={() => 50}
                                            >
                                                {({columnIndex, rowIndex, style}) => {
                                                    const header = headers[columnIndex - 1];
                                                    const screener = eod[rowIndex - 1];
                                                    let cell;
                                                    let extraClassName = "";


                                                    if(columnIndex === 0 && rowIndex === 0) {
                                                        return (<p style={style} className="flex-center border-b-2">NO</p>)
                                                    }

                                                    if(columnIndex === 0) {
                                                        return (<p style={style} className="flex-center border-b-2">{rowIndex}</p>)
                                                    }
                                                    if(rowIndex === 0) {
                                                        return (
                                                            <p style={style} className="flex-center border-b-2 cursor-pointer !flex-row gap-1" 
                                                                onClick={() => {
                                                                    if (sortBy === header) {
                                                                        setOrderBy(prev => prev === "asc" ? "desc" : "asc");
                                                                    } else {
                                                                        setSortBy(header);
                                                                    }
                                                                }}
                                                            >
                                                                {header}
                                                                {
                                                                    sortBy === header
                                                                    ? orderBy === "asc"
                                                                        ? <ChevronDown />
                                                                        : <ChevronUp />
                                                                    : null
                                                                }
                                                            </p>
                                                        )
                                                    }



                                                    if (header === "DATE") {
                                                        extraClassName += "items-center";
                                                        cell = isoStringToYYYYMMDD(screener[header]);
                                                    } else if(!isNaN(parseFloat(screener[header])) && header !== "TICKER") {
                                                        extraClassName += "items-end"
                                                        cell = screener[header];
                                                        if(parseFloat(screener[header]) > 999 && header !== "TICKER") {
                                                            cell = formatPrice(screener[header]);
                                                        }
                                                    } else if (!screener[header]) {
                                                        extraClassName += "items-end"
                                                        cell = "?"
                                                    } else {
                                                        extraClassName += "items-center";
                                                        cell = screener[header];
                                                    }

                                                    if(header === "TICKER") {
                                                        return (
                                                            <div style={style} className={`border-b-2 flex flex-col justify-center ${extraClassName}`}>
                                                                <a className="btn btn-link p-0 select-text" href={`https://www.tradingview.com/chart/?symbol=${screener["EXCHANGE"]}:${screener["TICKER"]}`} target="_blank" rel="noreferrer">
                                                                    {cell}
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div style={style} className={`border-b-2 flex flex-col justify-center px-4 ${extraClassName}`}>
                                                            {cell}
                                                        </div>
                                                    )
                                                }
                                            }
                                            </Grid>
                                        </> 
                                    )
                                }}
                            </AutoSizer>
                        )
                        : (<div className="h-full flex-center">No data found</div>)

                    }
                    </div>
                </div>
            </div>
        </>
    );
}
