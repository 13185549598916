import { Link, useLocation } from "react-router-dom";

export function NavItem({ icon, title, to, className }) {

    const location = useLocation();

    if(location.pathname === to) {

    }

    return (
        <>
            <Link className={`btn btn-ghost flex flex-row items-center ${location.pathname === to && "border-2 border-current hover:border-current"} ${className ?? ""} `} to={to} data-tip={title}>
                {icon}
                <p className="block lg:hidden min-w-12 text-start">{title}</p>
            </Link>
        </>
    )
}
