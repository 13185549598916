import { useRef, useState } from "react";
import { Download, StepForward } from 'lucide-react';
import { saveAs } from "file-saver";
import toast from 'react-hot-toast';
import JSZip from "jszip";
import Papa from 'papaparse';

export function MapCsvCategory() {
    const [category, setCategory] = useState("");
    const [loading, setLoading] = useState(false);
    const [zipName, setZipName] = useState("No file here");
    const [zipBlob, setZipBlob] = useState(null);
    const sourceInputRef = useRef(null);
    const targetInputRef = useRef(null);


    const handleProcess = async () => {
        toast.promise(
            processData(),
            {
                loading: "Converting txt file to csv...",
                success: "Result is ready to be saved!",
                error: (error) => error.message ?? "Something went wrong.",
            }
        );
    };

    const processData = async () => {
        setLoading(true);

        const sourceFiles = [...sourceInputRef.current.files];
        const targetFiles = [...targetInputRef.current.files];

        const sourcePromises = [];
        const targetPromise = [];

        if (sourceFiles.length < 1) {
            setLoading(false);
            throw new Error("Please choose your source file first")
        }

        if (targetFiles.length < 1) {
            setLoading(false);
            throw new Error("Please choose your target file first")
        }

        if (!category) {
            setLoading(false);
            throw new Error("Please put your target column name");
        }

        const data = {};
        const targetColumns = category.trim().split(",").filter(c => !!c).map(c => c.trim());

        for (const targetColumn of targetColumns) data[targetColumn] = {};

        const zip = new JSZip();

        for await (const sourceFile of sourceFiles) {
            const promise = new Promise((resolve, reject) => {
                Papa.parse(sourceFile, {
                    header: true,
                    step: (row) => {
                        const ticker = row.data["Ticker"] ?? row.data["Stock"];
                        for (const targetColumn of targetColumns) {
                            data[targetColumn][ticker] = row.data[targetColumn];
                        }
                    },
                    complete: (result) => {resolve(true)},
                    error: (error) => reject(error),
                });
            });
            sourcePromises.push(promise);
        }

        await Promise.all(sourcePromises);

        for (const targetFile of targetFiles) {
            const promise = new Promise((resolve, reject) => {
                const result = [];
                Papa.parse(targetFile, {
                    header: true,
                    download: true,
                    step: (row) => {
                        const ticker = row.data["Ticker"] ?? row.data["Stock"];
                        for(const targetColumn of targetColumns) {
                            row.data[targetColumn] = data[targetColumn][ticker] ?? "";
                        }
                        result.push(row.data);
                    },
                    complete: (_, file) => {
                        const csvData = Papa.unparse(result);
                        zip.file(file.name, csvData);
                        resolve(true);
                    },
                    error: (error) => reject(error),
                })
            });
            targetPromise.push(promise);
        }

        await Promise.all(targetPromise);

        const blob = await zip.generateAsync({ type: 'blob' });
        setZipBlob(blob);
        setZipName("result")
        setLoading(false);
    }

    const handleDownload = () => {
        if (zipBlob) {
            saveAs(zipBlob, `${zipName || "result"}.zip`);
        } else {
            toast.error("Nothing can download")
        }
    };

    return (
        <div className=" flex flex-col lg:flex-row w-full items-center justify-center gap-4">
            <div className="flex flex-col gap-4 w-full flex-1">
                <div className="flex-1 border w-full tooltip" data-tip="Source File">
                    <input ref={sourceInputRef} type="file" className="file-input w-full" multiple={true} accept='.txt,text/plain' />
                </div>
                <div className="flex-1 border w-full tooltip" data-tip="Target File">
                    <input ref={targetInputRef} type="file" className="file-input w-full" multiple={true} accept='.txt,text/plain' />
                </div>
            </div>
            <div className="flex flex-row flex-1 border w-full tooltip" data-tip="Use comma (,) for multiple column. E.g: Sector,Sub-Sector">
                <input type="text" className="input rounded-none w-full" placeholder="Column name" value={category} onChange={e => setCategory(e.target.value)} />
                <button className="btn btn-square btn-ghost rounded-none" onClick={handleProcess}>
                    {
                        loading
                            ? <span className='loading loading-bars loading-xs rotate-90 lg:rotate-0'></span>
                            : <StepForward className='rotate-90 lg:rotate-0' />
                    }
                </button>
            </div>
            <div className="flex-1 border p-[1px] w-full min-h-12 flex flex-row justify-between items-center">
                <div className='px-4'>
                    <input className='input focus:outline-none focus:border-none p-0 m-0' type="text" value={zipName} onChange={e => setZipName(e.target.value)} />
                </div>
                <button className="btn btn-square btn-primary rounded-none" disabled={loading} onClick={handleDownload}>
                    <Download />
                </button>
            </div>
        </div>
    )
}