import { Skull } from "lucide-react";
import toast from 'react-hot-toast';
import { calcEma, calcScreenerByFormula, fillUpMissingEodData, fillUpMissingMarketTrends, fillUpMissingTick, initDb, removeAllEma, removeAllMakketTrends, removeAllTicks, resetMyxStockData } from '../utils/web-services'
import { SimpleDialog } from "../components/simple-dialog";
import { useAuth } from "../contexts/auth-context";
import { formulaList } from "../utils/common";
export function Danger() {
    const { logout }  = useAuth();
    const handleInitDb = async () => {
        try {
            await toast.promise(initDb(), {
                success: "Init successful",
                error: "Something went wrong",
                loading: "Initializing"
            });
            logout();
        } catch (error) {
            console.error(error);
        } 
    }

    const handleResetMyxStockData = async () => {
        try {
            await toast.promise(resetMyxStockData(), {
                success: "Reset successful",
                error: "Something went wrong",
                loading: "Reset MYX Stock Data"
            });
        } catch (error) {
            console.error(error);
        } 
    }

    const handleFillUpMissingEod = () => {
        try {
            toast.promise(fillUpMissingEodData(), {
                success: "DONE",
                error: "Something went wrong",
                loading: "Filling up"
            })
        } catch (error) {
            console.error(error);
        }
    }

    const handleRecalculateEma = async () => {
        try {
            await toast.promise(removeAllEma(), {
                loading: "Removing all EMA value",
                success: "Removed all EMA value",
                error: "Remove all EMA value fail"
            });
            await toast.promise(calcEma(), {
                success: "Calculated the EMA value",
                loading: "Calculating EMA value",
                error: "Error on calculating EMA value"
            })
        } catch (error) {
            console.error(error);
        }
    }

    const handleRecalculateMarketTrends = async () => {
        try {
            await toast.promise(removeAllMakketTrends(), {
                loading: "Removing all Market Trend",
                success: "Removed all Market Trend",
                error: "Remove all Market Trend fail"
            });
            await toast.promise(fillUpMissingMarketTrends(), {
                success: "Calculated the Market Trend",
                loading: "Calculating Market Trend",
                error: "Error on calculating Market Trend"
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleRecalculateTick = async () => {
        try {
            await toast.promise(removeAllTicks(), {
                loading: "Removing all Ticks",
                success: "Removed all Ticks",
                error: "Remove all Ticks fail"
            });
            await toast.promise(fillUpMissingTick(), {
                success: "Calculated the Tick",
                loading: "Calculating Tick",
                error: "Error on calculating Tick"
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleCalculateMissingFormulaData = async () => {
        try {
            const formulars = formulaList;
            for(const formula of formulars) {
                const formulaName = formula.replaceAll("_", " ");
                await toast.promise(calcScreenerByFormula(formula), {
                    loading: `Calculating ${formulaName}`,
                    success: `Calculated ${formulaName} SUCCESS`,
                    error: `Calculation ${formulaName} FAIL`
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className="w-full h-screen max-h-full lg:max-h-screen">
                <div className="flex flex-col  p-4 h-full overflow-hidden">
                    <h2 className="text-3xl font-bold mb-4 flex flex-row items-center space-x-2 flex-wrap"><Skull strokeWidth={3} /><span>DANGER ZONE</span></h2>
                    <div className='flex flex-col space-y-4'>
                        <SimpleDialog
                            onSubmit={handleInitDb}
                            buttonTxt='Initialize Database'
                            title={"WARNING!!!"}
                            content={"This action will reset all the data inside the database. And you unable to recover the data."}
                        />
                        <SimpleDialog
                            onSubmit={handleResetMyxStockData}
                            buttonTxt='Reset MYX Stock Pre-Built Data'
                            title={"WARNING!!!"}
                            content={"This action will reset all the myx stock data including CODE, TICKER, SECTOR, INDUSTRY inside the database. And you unable to recover the data."}
                        />
                        <SimpleDialog
                            onSubmit={handleFillUpMissingEod}
                            buttonTxt='Fill EOD Data'
                            title={"WARNING!!!"}
                            content={"Fill up the missing Share Issue, Market Capitalization value"}
                        />
                        <div className="divider" />
                        <SimpleDialog
                            onSubmit={handleRecalculateEma}
                            buttonTxt='Recalculate Ema'
                            title={"WARNING!!!"}
                            content={"This action will change a lot the data inside the database. And you unable to recover the data."}
                        />
                        <SimpleDialog
                            onSubmit={handleRecalculateMarketTrends}
                            buttonTxt='Recalculate Market Trends'
                            title={"WARNING!!!"}
                            content={"This action will change a lot the data inside the database. And you unable to recover the data."}
                        />
                        <SimpleDialog
                            onSubmit={handleRecalculateTick}
                            buttonTxt='Recalculate EOD Tick'
                            title={"WARNING!!!"}
                            content={"This action will change a lot the data inside the database. And you unable to recover the data."}
                        />
                        <div className="divider" />
                        <SimpleDialog
                            onSubmit={handleCalculateMissingFormulaData}
                            buttonTxt='Calculate Missing Formula Data'
                            title={"WARNING!!!"}
                            content={"This action will change a lot the data inside the database. And you unable to recover the data."}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}