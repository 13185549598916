import { formulaList, months } from "../../utils/common";
import { useEffect, useRef, useState } from "react";
import { fetchScreeners } from "../../utils/web-services";
import { Send } from "lucide-react";
import toast from "react-hot-toast";

export function ByFormula({ onChange }) {
    const [loading, setLoading] = useState(false);

    const now = new Date();

    const formulas = formulaList;

    const [selectedFormula, setSelectedFormula] = useState("DROP_GAO_GAO");

    const [year, setYear] = useState(now.getFullYear());

    const monthRef = useRef(null);

    const [refresh, setRefresh] = useState(false);

    const onRefresh = () => setRefresh(!refresh);

    const handleYearSubmit = () => {
        if (year <= 1999 || year >= 2500 || isNaN(parseInt(year))) {
            return toast.error("Invalid year input");
        } else {
            onRefresh();
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (!!selectedFormula) {
                const month = parseInt(monthRef.current?.value) ?? 0;
                const result = await fetchScreeners("formula", `${selectedFormula}-${year}-${month + 1}`);
                onChange(result);
            }
            setLoading(false)
        })()
    }, [selectedFormula, refresh]);

    return (
        <>
            <div className="dropdown dropdown-end w-full h-full z-20">
                {
                    loading
                    ?   <div className="bg-base-200 h-full w-full flex-center"><div className="loading loading-bars"/></div>
                    : <div tabIndex={0} role="button" className="btn w-full">{selectedFormula ?? "Choose Formula"}</div>
                }
                
                <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-72">
                    {
                        formulas.map((formula, index) => (
                            <li key={index} className={`btn btn-ghost ${formula === selectedFormula && "disabled bg-base-200"}`} onClick={() => setSelectedFormula(formula)}>{formula}</li>
                        ))
                    }
                    <div className="divider m-0" />
                    <div className="flex flex-row gap-4">
                        <input type="number" name="year" id="year" className="input w-full" value={year} onChange={(e) => setYear(e.target.value)} />
                        <select className="select text-md w-full max-w-xs" defaultValue={now.getMonth()} ref={monthRef}>
                            {
                                months.map((m, index) => {
                                    return (<option key={index} value={index} className={"btn"}>{m}</option>)
                                })
                            }
                        </select>
                        <button className="btn btn-square btn-primary" onClick={handleYearSubmit}>
                            <Send />
                        </button>
                    </div>
                </ul>
            </div>
        </>
    )
}