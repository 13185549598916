import { LogOut } from 'lucide-react';
import { useAuth } from '../contexts/auth-context';
import { useNavigate } from 'react-router-dom';

export function LogoutButton(props) {

    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    }

    return (
        <button onClick={handleLogout} className={"btn btn-ghost flex justify-center items-center " + props.className}>
            <LogOut />
        </button>
    )
}