import { convertFilenameToYYYYMMDD } from "./common";


// const domain = process.env.EXPRESS_BACKEND_DOMAIN || "http://192.168.68.115:8080";
const domain = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "https://xsceeners.com";

export const performLogin = async (email, password) => {
  try {
    const response = await fetch(`${domain}/api/public/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password,
        channel: "web",
      }),
    });
  
    if (!response.ok) {
      console.error("Login failed with status: ", response.status);
      return null;
    }
  
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    return null
  }
}

export const performLogout = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
  
    return response.ok;
  } catch (error) {
    console.error(error);
  }
}

export const getServerConnectionStatus = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/connection-status`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });

    return response;
  } catch (error) {
    return false;
  }
}

export const initDb = async () => {

  const sessionId = localStorage.getItem('sessionId');
  const response = await fetch(`${domain}/api/private/reset-db`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response}`);
  }
  return true;
}

export const resetMyxStockData = async () => {

  const sessionId = localStorage.getItem('sessionId');
  const response = await fetch(`${domain}/api/private/reset-myx-stock-data`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response}`);
  }
  return true;
}

export const uploadDataCsvFile = async (file, exchange) => {
    const date = convertFilenameToYYYYMMDD(file.name);
    if(!date) {
      return false;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('exchange', exchange.toUpperCase());
    formData.append('date', date);

    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/upload-eod-data`, {
      method: 'POST',
      headers: {
        sessionId: sessionId
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Upload ${file.name} Failed`);
    }
    return true;
}

export const fillUpMissingEodData = async () => {
  const sessionId = localStorage.getItem('sessionId');
  const response = await fetch(`${domain}/api/private/fill-up-missing-eod-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      sessionId: sessionId
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response}`);
  }
  return true;
}


export const fillUpMissingMarketTrends = async () => {
  const sessionId = localStorage.getItem('sessionId');
  const response = await fetch(`${domain}/api/private/fill-up-missing-market-trends`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      sessionId: sessionId
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response}`);
  }
  return true;
}

export const fillUpMissingTick = async () => {
  const sessionId = localStorage.getItem('sessionId');
  const response = await fetch(`${domain}/api/private/missing-tick`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      sessionId: sessionId
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response}`);
  }
  return true;
}

export const fetchEodByDateAndExchange = async (date, exchange) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/eod-data?date=${date}&exchange=${exchange}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error)
    return [];
  }
}

export const removeAllEma = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/remove-all-ema`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

export const removeAllMakketTrends = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/remove-all-market-trends`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

export const removeAllTicks = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/remove-all-ticks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

export const calcEma = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/calc-ema`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

export const fetchStocksByExchange = async (exchange) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/stocks?exchange=${exchange}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error)
    return [];
  }
}

export const fetchEodByTicker = async (ticker, exchange) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/eod-ema-by-ticker?ticker=${ticker}&exchange=${exchange}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error)
    return [];
  }
}

export const fetchMarketTrendsByExchange = async (exchange) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/market-trends?&exchange=${exchange}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error)
    return [];
  }
}
export const fetchSectorTrendsByExchangeAndDate = async (exchange, date) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/sector-trends?&exchange=${exchange}&date=${date}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error)
    return [];
  }
}

export const uploadMyxData = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const sessionId = localStorage.getItem('sessionId');
  
  const response = await fetch(`${domain}/api/private/myx-data`, {
    method: 'POST',
    headers: {
      sessionId: sessionId
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`Upload MYX Data Failed`);
  }

  const json = await response.json()
  return json.affectedRows;
}

export const fetchFormulaPreferences = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/formula-preferences`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if (response.ok) {
      const json = await response.json();
      return json["data"];
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const updateFormulaPreferences = async (formulaPreferences) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/formula-preferences`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId,
      },
      body: JSON.stringify({
        formulaPreferences: formulaPreferences
      }),
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const calcEmaMeet = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/ema-meet`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const calcEmaMeetPro = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/ema-meet-pro`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const calcDropGaoGao = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/drop-gao-gao`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const calcPriceDiminish = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/price-diminish`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const calcWindicator = async () => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/windicator`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const calcScreenerByFormula = async (formula) => {
  const suffix = formula.toLowerCase().replaceAll("_", "-");
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/${suffix}`, {
      method: "POST",
      headers: {
        sessionId: sessionId
      }
    });

    if(!response.ok) {
      throw new Error(`Fail when calculate ${formula}`)
    }
    return true;
  } catch (error) {
    console.error(error);
    throw error
  }
}

export const fetchScreeners = async (by, value) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/screeners?by=${by}&value=${value}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });

    if(!response.ok) return null;

    const json = await response.json();
    return json.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const removeScreenerByFormula = async (formula) => {
   try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/screener/${formula}`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if(!response.ok) {
      throw new Error(`Fail when calculate ${formula}`)
    }
    return true;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const removeAllScreeners = async (formula) => {
  try {
    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/screeners`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        sessionId: sessionId
      }
    });
    if(!response.ok) {
      throw new Error(`Fail when calculate ${formula}`)
    }
    return true;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const testStrategy = async (file, testerPreference) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("json", JSON.stringify({testerPreference: testerPreference}));

    const sessionId = localStorage.getItem('sessionId');
    const response = await fetch(`${domain}/api/private/test-strategy`, {
      method: 'POST',
      headers: {
        sessionId: sessionId
      },
      body: formData
    }); 
    if(response.ok) {
          const json = await response.json();
          return json.result;
    } else {
      throw new Error("Fail to test the strategy")
    }
}