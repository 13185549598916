import React from 'react';
import { useAuth } from '../contexts/auth-context';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  const { sessionId } = useAuth();

  const location = useLocation();
  
  return sessionId ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
