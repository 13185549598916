import { useEffect, useState } from "react"
import { useAuth } from "../contexts/auth-context";
import toast from "react-hot-toast";
import { Logo } from "../icons/logo";
import { useLocation, useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
export function Login() {

    const { sessionId, login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setLoginData((prev) => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSubmit = () => {
        const email = loginData.email;
        const password = loginData.password;
        if(!email || !password) return toast.error("Pls fill up the form.")
            
        login(email, password);
    }

    useEffect(() => {
        if (!!sessionId) {
            navigate(from);
        }
    }, [sessionId]);

    return (
        <>
            <div className="min-h-screen w-full overflow-x-hidden overflow-y-auto flex-center" data-theme="lofi">
                <div className="flex-center gap-4 w-[90%] max-w-sm">
                        <div className="w-sm flex-center">
                            <Logo className={"!min-w-48 !min-h-48"} />
                        </div>
                        <input className="input input-bordered w-full" placeholder="Email" type="email" name="email" value={loginData.email} onChange={handleOnChange} />
                        <div className="w-full relative">

                        <input className="input input-bordered w-full" placeholder="Password" type={passwordVisibility ? "text" : "password"} name="password" value={loginData.password} onChange={handleOnChange} />
                        <button className="btn btn-square btn-ghost absolute top-0 right-0" onClick={() => setPasswordVisibility(!passwordVisibility)}>
                                {
                                    passwordVisibility
                                    ? <Eye />
                                    : <EyeOff />
                                }
                            </button>
                        </div>
                        <button className="btn btn-primary w-full" onClick={handleSubmit}>
                            LOGIN
                        </button>
                </div>

            </div>
        </>
    )
}