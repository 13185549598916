import { useEffect, useRef, useState } from "react"
import { HKEX, MYX, NASDAQ, NYSE } from "../icons/exchange";
import { fetchEodByTicker, fetchStocksByExchange } from "../utils/web-services";
import { init, dispose } from 'klinecharts'
import { isoStringToTimestamp } from "../utils/common";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

import toast from "react-hot-toast";

export function StockChart() {
    const [stocks, setStocks] = useState([]);
    const [stockInfo, setStockInfo] = useState(null);
    const [ticker, setTicker] = useState({});
    const [exchange, setExchange] = useState("NASDAQ");
    const [loading, setLoading] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const stockDialog = useRef(null);

    const exchangeIcon = {
        NASDAQ: <NASDAQ />,
        NYSE: <NYSE />,
        MYX: <MYX />,
        HKEX: <HKEX />,
    }


    useEffect(() => {
        (async () => {
            setLoading(true);
            setStocks([]);
            setTicker({});
            const result = await fetchStocksByExchange(exchange);
            setStocks(result);
            setLoading(false);
        })();
    }, [exchange]);

    useEffect(() => {
        if (stocks.length > 0) {
            setTicker(stocks[0] ?? {});
        }
    }, [stocks]);

    useEffect(() => {
        (async () => {
            if (!!ticker["TICKER"]) {
                try {
                    dispose('chart')
                    setResetKey(prev => prev + 1);
                    const result = await fetchEodByTicker(ticker["TICKER"], exchange);
                    
                    setStockInfo((!!result && !!result.stock) ? result.stock : null);



                    if (!!result && !!result.eod.length > 0) {
                        const chart = init('chart', {
                            candle: {
                                type: 'candle_solid',
                                bar: {
                                    upColor: '#F92855',
                                    upBorderColor: '#F92855',
                                    upWickColor: '#F92855',

                                    downColor: '#2DC08E',
                                    downBorderColor: '#2DC08E',
                                    downWickColor: '#2DC08E',

                                    noChangeColor: '#F92856',
                                    noChangeBorderColor: '#F92856',
                                    noChangeWickColor: '#F92856'
                                },
                                priceMark: {
                                    last: {
                                        show: false,
                                    }
                                }
                            }
                        })

                        const data = result.eod.map((row) => {
                            return {
                                close: row["CLOSE"],
                                high: row["HIGH"],
                                low: row["LOW"],
                                open: row["OPEN"],
                                timestamp: isoStringToTimestamp(row["DATE"]),
                                volume: row["VOLUME"]
                            };
                        });
                        chart.applyNewData(data);
                        return () => {
                            dispose('chart')
                        }
                    }

                } catch (error) {
                    toast.error("Something went wrong please refresh the page.")
                    console.error(error);
                }
            }
        })()
    }, [ticker]);

    const handleOpenModal = () => stockDialog.current.showModal();



    return (
        <>
            <div className="flex flex-col pb-12">
                <div className="flex flex-col lg:flex-row">
                    <button className="btn" onClick={handleOpenModal}>
                        {
                            loading
                                ? "Loading"
                                : !ticker["TICKER"]
                                    ? "?"
                                    : ticker["TICKER"]
                        }
                    </button>
                    <dialog className="modal" ref={stockDialog}>
                        <div className="modal-box">
                            <form method="dialog">
                                <div className="min-h-96 max-h-[90vh]">
                                    {
                                        stocks.length > 0
                                            ? (
                                                <AutoSizer>
                                                    {({ height, width }) => {
                                                        return (
                                                            <List
                                                                height={height}
                                                                itemCount={stocks.length}
                                                                itemSize={50}
                                                                width={width}
                                                            >
                                                                {({ index, style }) => {
                                                                    const stock = stocks[index];
                                                                    return (
                                                                        <button style={style} className="btn btn-ghost rounded-none justify-start" onClick={() => setTicker(stock)}>
                                                                            {stock["TICKER"]}
                                                                        </button>
                                                                    )
                                                                }}

                                                            </List>
                                                        )
                                                    }}
                                                </AutoSizer>
                                            )
                                            : <button className="btn btn-ghost w-full">No ticker found</button>
                                    }
                                </div>
                            </form>
                        </div>
                        <form method="dialog" className="modal-backdrop">
                            <button>close</button>
                        </form>
                    </dialog>   
                    <div className="divider lg:divider-horizontal my-2 lg:mx-2" />
                    <div className="flex flex-col lg:flex-row w-full justify-end space-y-4 lg:space-y-0 lg:space-x-4">
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NASDAQ" ? "btn-primary" : ""}`} onClick={() => setExchange("NASDAQ")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                {exchangeIcon["NASDAQ"]}
                            </div>
                            NASDAQ
                        </button>
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "NYSE" ? "btn-primary" : ""}`} onClick={() => setExchange("NYSE")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                {exchangeIcon["NYSE"]}
                            </div>
                            NYSE
                        </button>
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "MYX" ? "btn-primary" : ""}`} onClick={() => setExchange("MYX")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                {exchangeIcon["MYX"]}
                            </div>
                            MYX
                        </button>
                        <button disabled={loading} className={`btn flex-1 flex-nowrap ${exchange === "HKEX" ? "btn-primary" : ""}`} onClick={() => setExchange("HKEX")}>
                            <div className="rounded-[100px] border overflow-hidden">
                                {exchangeIcon["HKEX"]}
                            </div>
                            HKEX
                        </button>
                    </div>
                </div>
                <div className="divider" />
                <div className="border">
                    {
                        !stockInfo
                        ? <p>Please select a Ticker</p>
                        : (
                            <>
                                <table className="table">
                                    <tbody>
                                        {
                                            ["EXCHANGE", "TICKER", "SECTOR", "INDUSTRY", "EOD_COUNT"].map((header, index) => (
                                                <tr key={index}>
                                                    <th className="text-end">{header}</th>
                                                    <td className="text-center p-0">{":"}</td>
                                                    <td className="font-bold">{stockInfo[header]}</td>
                                                </tr>        
                                            ))
                                        }   
                                    </tbody>
                                </table>
                            </>
                        )
                    }
                </div>
                <br />  
                <div className="h-[90vh]">
                    <div className="w-full h-full overflow-auto border">
                        {
                            loading
                                ? <div className="skeleton w-full h-full" />
                                : !ticker["TICKER"]
                                    ? <div className="flex-center w-full h-full">Nothing here</div>
                                    : (
                                        <div key={resetKey} id="chart" className="w-full h-full" />
                                    )
                        }
                    </div>
                </div>
            </div >

        </>
    )
}