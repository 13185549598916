import { useState } from "react"
import { EodTable } from "../components/eod-table";
import { StockChart } from "../components/stock-chart";

export function Data() {
    const [tab, setTab] = useState("eod");

    const tabs = {
        "eod": <EodTable />,
        "stock": <StockChart />,
    }

    const content = tabs[tab];

    return (
        <>
            <div className="w-full h-screen max-h-full lg:max-h-screen">
                <div className="flex flex-col space-y-4 p-4 h-full">
                    <h2 className="text-3xl font-bold mb-4">Data</h2>
                    <div role="tablist" className="tabs tabs-boxed">
                        {
                            Object.keys(tabs).map((key, index) => (
                                <div key={index} role="tab" className={`tab ${key === tab ? "tab-active" : ""}`} onClick={() => setTab(key)}>{key.toUpperCase()}</div>
                            ))
                        }
                    </div>
                    <div>
                        {content}
                    </div>
                </div>
            </div>
        </>
    )
}