import { Calculator } from "lucide-react";
import { useRef } from "react";
import { calcScreenerByFormula, removeScreenerByFormula } from "../utils/web-services";
import toast from "react-hot-toast";
import { formulaList } from "../utils/common";

export function FormulaRefreshButton() {
    const formulas = formulaList;
    const settingDialog = useRef(null);

    const handleOpenModal = () => settingDialog.current.showModal();

    const handleSubmit = async (formula) => {
        const formulaName = formula.replaceAll("_", " ");
        try {
            await toast.promise(removeScreenerByFormula(formula), {
                loading: `Removing ${formulaName}`,
                success: `Remove ${formulaName} SUCCESS`,
                error: `Remove ${formulaName} FAIL`
            });
            await toast.promise(calcScreenerByFormula(formula), {
                loading: `Calculating ${formulaName}`,
                success: `Calculated ${formulaName} SUCCESS`,
                error: `Calculation ${formulaName} FAIL`
            });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const handleSubmitAll = async () => {
        try {
            for (const formula of formulas) {
                const calculationResult = await handleSubmit(formula);
                if(!calculationResult) return;
            }
        } catch (error) {
            console.error(error);
            return;
        }
        
    }


    return (
        <>
            <button className="btn btn-square" onClick={handleOpenModal}>
                <Calculator />
            </button>
            <dialog ref={settingDialog} className="modal">
            <div className="modal-box">
                <h3 className="font-bold text-lg">Recalculate Screener</h3>
                <div className="modal-action w-full">
                    <form method="dialog" className="w-full">
                        <div className="flex flex-col gap-4 w-full">
                            <button className="btn w-full" onClick={handleSubmitAll}>
                                ALL
                            </button>
                            {
                                formulas.map((formula, index) => (
                                    <button key={index} className="btn w-full" onClick={() => handleSubmit(formula)}>
                                        {formula}
                                    </button>
                                ))
                            }
                            <button className="btn btn-primary">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
            </dialog>
        </>
    )
}