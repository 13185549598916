import { Outlet } from "react-router-dom";
import { NavBar } from "../components/nav-bar"
import { ServerStatus } from "../components/server-status";
export function Layout() {
    return (
        <>
            <div className="fixed top-4 right-4 z-50">
                <ServerStatus />
            </div>
            
            <div className="relative h-[100vh] w-full flex flex-col lg:flex-row">
                <NavBar />
                <div className="divider lg:divider-horizontal m-0" />
                <div className="w-full h-screen overflow-auto">
                    <Outlet />
                </div>
            </div>
        </>
    )
}